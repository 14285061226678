import { Button } from "react-bootstrap";
import { Modal as ModalWindow, ModalBody } from "reactstrap";
import React from "react";

const ConfirmAlert = ({ title, message, buttons }) => {
  return (
    <ModalWindow isOpen={true}>
      <ModalBody>
        <h1>{title}</h1>
        {message}
        <br />
        <br />

        {buttons.map((button) => {
          return (
            <Button
              key={button.label}
              className={["mx-1", button.className]}
              onClick={button.onClick}
            >
              {button.label}
            </Button>
          );
        })}
      </ModalBody>
    </ModalWindow>
  );
};
export default ConfirmAlert;
