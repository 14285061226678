import React, { useContext } from "react";
import FormInput from "components/cmation-formsbuilder";
import { UnitPurchaseContext } from "../../../UnitModal";

const SelectHotelCustomerName = () => {
  const [lastName, setLastName] = React.useState("");
  const [roomNumber, setRoomNumber] = React.useState("");
  const UnitsPurchaseData = useContext(UnitPurchaseContext);

  const saveGuestName = () => {
    UnitsPurchaseData.setHotelRoomNumber(roomNumber);
    UnitsPurchaseData.setHotelLastName(lastName);
    UnitsPurchaseData.updateUnitStep("Select Equipment");
  };

  return (
    <>
      {
        <div className="row bg-light border-bottom border-top mt-3">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="exampleFormControlInput1">Last Name</label>
              <FormInput
                type="text"
                className="form-control"
                id="lastName"
                name="lastName"
                placeholder="enter last name ..."
                onChange={(value) => setLastName(value)}
                defaultValue={lastName}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="exampleFormControlInput1">Room Number</label>
              <FormInput
                type="text"
                className="form-control"
                id="roomNumber"
                name="roomNumber"
                placeholder="enter room number ..."
                onChange={(value) => setRoomNumber(value)}
                defaultValue={roomNumber}
              />
            </div>
          </div>
        </div>
      }
      <div className="row justify-content-md-center mt-3">
        <div className="col-sm-12 col-md-6 text-center">
          <button
            className="btn btn-block btn-secondary btn-lg mt-4"
            onClick={() => saveGuestName()}
            disabled={lastName === "" || roomNumber === ""}
          >
            CONTINUE
          </button>
        </div>
      </div>
    </>
  );
};

export default SelectHotelCustomerName;
