import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
  const currentLocation = useLocation();
  const [location, setLocation] = useState("");

  useEffect(() => {
    if (currentLocation !== location) {
      window.scrollTo(0, 0);
      setLocation(currentLocation);
    }
  }, [currentLocation, location]);
  return props.children;
};

export default ScrollToTop;
