import React from "react";

const itemRadio = (props) => {
  return (
    <label
      className="form-check-label w-100"
      htmlFor={`unitType${props.value}`}
    >
      <div className="input-group input-group-lg w-100 mb-3">
        <span className="input-group-text border-secondary">
          <input
            id={`unitType${props.value}`}
            className="form-check-input p-0 m-0"
            type="radio"
            name={props.name}
            value={props.value}
            checked={props.checked}
            onChange={(e) => props.onChange(e.target.value, props.unitPrice)}
          />
        </span>
        {props.unitPrice &&
          props.unitPrice !== "" &&
          props.unitPrice !== "0.00" && (
            <div className="input-group-text text-white bg-secondary border-secondary fs-6 m-0 px-1">
              {props.unitPrice !== "0.00" &&
                typeof props.unitPrice !== "undefined" &&
                "$" + props.unitPrice}
            </div>
          )}
        <div className="input-group-text flex-fill border-secondary fs-6 m-0 px-1">
          {props.label}
        </div>
      </div>
    </label>
  );
};

export default itemRadio;
