import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";

const InputError = (props) => {
  if (!props.error) return null;
  return (
    <div className="col-12">
      <div className="p-1 alert alert-danger" role="alert">
        <div className="alert-message">
          <FontAwesomeIcon icon={faBell} fixedWidth /> {props.error}
        </div>
      </div>
    </div>
  );
};
export default InputError;
