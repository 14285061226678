import React from "react";
import loading from "../../assets/foot.png";
const Spinner = () => {
  return (
    <div className="text-center mt-3">
      <br />
      <br />
      <br />
      <br />
      <img
        src={loading}
        alt="Loading"
        className="logoSpin"
        style={{ width: 150 }}
      />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};
export default Spinner;
