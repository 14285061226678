import React, { useContext, useEffect } from "react";
import { UnitPurchaseContext } from "../../../UnitModal";
import { ApiURL } from "../../../../../../config";
import FormInput from "../../../../../cmation-formsbuilder";

const SelectHotelCustomerNameWyndham = () => {
  const [manualEntry, setManualEntry] = React.useState(0);
  const [hotelLastName, setHotelLastName] = React.useState("");
  const [hotelRoomNumber, setHotelRoomNumber] = React.useState("");
  const [hotelResults, setHotelResults] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const UnitsPurchaseData = useContext(UnitPurchaseContext);
  const authToken = localStorage.getItem("authToken");

  const saveWyndhamGuestName = (
    hotelRoomNumber,
    hotelLastName,
    manualEntry,
    additional,
  ) => {
    UnitsPurchaseData.setHotelRoomNumber(hotelRoomNumber);
    UnitsPurchaseData.setHotelLastName(hotelLastName);
    UnitsPurchaseData.setWyndhamExtraCharge(additional);
    UnitsPurchaseData.setHotelManualEntry(manualEntry ? 1 : 0);
    UnitsPurchaseData.updateUnitStep("Select Equipment");
  };

  const searchHotel = () => {
    setLoading(true);
    fetch(`${ApiURL}/searchWyndham`, {
      method: "POST",
      body: JSON.stringify({
        hotelLastName: hotelLastName,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.status) {
          setHotelResults(res.data);
        } else {
          setHotelResults([]);
        }
      })
      .catch((err) => {
        console.log("authLogin Error", err);
      });
  };

  useEffect(() => {
    searchHotel();
  }, [hotelLastName]);

  return (
    <>
      <div className="row bg-light border-bottom border-top mt-3 py-3">
        <div className="col-4 pt-2">
          <FormInput
            type="toggle"
            name="manualEntry"
            label="Manual Entry"
            id="manualEntry"
            value={manualEntry}
            onChange={(val) => setManualEntry(val)}
          />
        </div>
        <div className="col-4">
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1">Last Name</label>
            <FormInput
              type="text"
              className="form-control"
              id="hotelLastName"
              name="hotelLastName"
              placeholder="enter last name ..."
              onChange={(value) => {
                setHotelLastName(value);
              }}
              value={hotelLastName}
            />
          </div>
        </div>
        <div className="col-4">
          {manualEntry === 1 && (
            <div className="form-group">
              <label htmlFor="exampleFormControlInput1">Room Number</label>
              <FormInput
                type="number"
                className="form-control"
                id="hotelRoomNumber"
                name="hotelRoomNumber"
                placeholder="enter room number ..."
                onChange={(value) => {
                  setHotelRoomNumber(value);
                }}
                value={hotelRoomNumber}
              />
            </div>
          )}
        </div>
      </div>

      {manualEntry === 0 && (
        <>
          <table className="mt-3 table table-striped">
            <thead className="thead-dark text-light">
              <tr>
                <th width="15%">&nbsp;</th>
                <th width="15%">Room</th>
                <th width="30%">Name</th>
                <th width="30%">Alt Name</th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan="4" className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
              )}
              {hotelResults && hotelResults.length === 0 && (
                <tr>
                  <td colSpan="4" className="text-center">
                    <span className="sr-only">No guests found</span>
                  </td>
                </tr>
              )}
              {!loading &&
                typeof hotelResults !== "undefined" &&
                hotelResults !== null &&
                (hotelRoomNumber !== null || hotelLastName !== null) && (
                  <>
                    {hotelResults && typeof hotelResults !== "undefined" && (
                      <>
                        {hotelResults.map((room, index) => {
                          let roomNumber = "";
                          if (hotelLastName !== "") {
                            // change room number to
                            let i;
                            if (typeof room.room !== "undefined") {
                              for (i = 0; i < room.room.length; i++) {
                                if (i === 0 || i === 1) {
                                  roomNumber += "X";
                                } else {
                                  roomNumber += room.room[i];
                                }
                              }
                            }
                          } else {
                            roomNumber = room.room;
                          }

                          return (
                            <tr key={index}>
                              <td nowrap="true">
                                {typeof room.room !== "undefined" && (
                                  <>
                                    {room.additional === 1 && (
                                      <button
                                        onClick={() =>
                                          saveWyndhamGuestName(
                                            room.room,
                                            room.guestName,
                                            manualEntry,
                                            1,
                                          )
                                        }
                                        className="btn btn-success btn-sm"
                                      >
                                        SELECT
                                      </button>
                                    )}
                                    {room.additional === 0 &&
                                      room.room !== "" &&
                                      typeof room.room !== "undefined" && (
                                        <button
                                          onClick={() =>
                                            saveWyndhamGuestName(
                                              room.room,
                                              room.guestName,
                                              manualEntry,
                                              0,
                                            )
                                          }
                                          className="btn btn-primary btn-sm"
                                        >
                                          SELECT
                                        </button>
                                      )}
                                  </>
                                )}
                              </td>
                              <td>{roomNumber}</td>
                              <td>{room.guestName}</td>
                              <td>{room.Accompanying_Guest_Name} &nbsp;</td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
            </tbody>
          </table>
        </>
      )}

      {manualEntry === 1 &&
        hotelLastName !== "" &&
        hotelLastName !== null &&
        hotelRoomNumber !== "" &&
        hotelRoomNumber !== null && (
          <div className="row justify-content-md-center mt-3">
            <div className="col-sm-12 col-md-6 text-center">
              <button
                className="btn btn-block btn-secondary btn-lg mt-4"
                onClick={() =>
                  saveWyndhamGuestName(
                    hotelRoomNumber,
                    hotelLastName,
                    manualEntry,
                    0,
                  )
                }
              >
                CONTINUE
              </button>
            </div>
          </div>
        )}
    </>
  );
};

export default SelectHotelCustomerNameWyndham;
