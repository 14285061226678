import Addons from "./Addons";
import Equipment from "./Equipment";

const Items = ({
  equipmentData,
  selectedEquipment,
  selectedAddOns,
  toggleCheckboxChange,
  onChangeRadio,
  currentTime,
}) => {
  return (
    <>
      <div className="row mx-1">
        {equipmentData?.equipment
          .filter(
            (equipment) =>
              parseInt(equipment.startTime) <= currentTime &&
              parseInt(equipment.endTime) >= currentTime,
          )
          .map((unit, index) => {
            return (
              <Equipment
                key={`equipment-${index}`}
                unit={unit}
                selectedEquipment={selectedEquipment}
                onChangeRadio={onChangeRadio}
              />
            );
          })}
      </div>
      <div className="row mx-1">
        <div className="col-sm-12 px-0">
          <h3 className="mt-3 mx-0 px-0">Extras</h3>
        </div>
      </div>
      <div className="row mx-1">
        {equipmentData?.addons
          .filter(
            (equipment) =>
              parseInt(equipment.startTime) <= currentTime &&
              parseInt(equipment.endTime) >= currentTime,
          )
          .map((unit, index) => {
            return (
              <Addons
                key={`addons-${index}`}
                unit={unit}
                selectedAddOns={selectedAddOns}
                toggleCheckboxChange={toggleCheckboxChange}
              />
            );
          })}
      </div>
    </>
  );
};
export default Items;
