import React from "react";

const itemCheckbox = (props) => {
  const toggleCheckboxChange = (e) => {
    props.handleCheckboxChange(e.target.value);
  };

  return (
    <label className="form-check-label w-100" htmlFor={`checkbox${props.id}`}>
      <div className="input-group input-group-lg w-100 mb-3">
        <span className="input-group-text border-secondary">
          <input
            id={`checkbox${props.id}`}
            name={props.name}
            type="checkbox"
            defaultChecked={props.checked}
            value={props.value}
            onChange={toggleCheckboxChange}
            className="form-check-input"
          />
        </span>
        {props.unitPrice && props.unitPrice !== "0.00" && (
          <div className="input-group-text text-white bg-secondary border-secondary fs-6 m-0 px-1">
            {props.unitPrice !== "0.00" &&
              typeof props.unitPrice !== "undefined" &&
              "$" + props.unitPrice}
          </div>
        )}
        <div className="input-group-text flex-fill border-secondary fs-6 m-0 px-1">
          {props.label}
        </div>
      </div>
    </label>
  );
};

export default itemCheckbox;
