import { Tab, Tabs } from "react-bootstrap";
import TabNumber from "./tabs/TabNumber";
import TabNewCustomer from "./tabs/TabNewCustomer";
import TabLog from "./tabs/TabLog";
import TabNotes from "./tabs/TabNotes";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UnitsContext } from "./Dashboard";
import { connect } from "react-redux";
import { logAction, getUnit } from "client/actions/apiActions";
import TabCustomerList from "./tabs/TabCustomerList";
import LogoLoading from "../../layout/LogoLoading";
import TabMoveCustomer from "./tabs/TabMoveCustomer";

export const UnitPurchaseContext = createContext(null);

const UnitModal = (props) => {
  const UnitsData = useContext(UnitsContext);

  // dayjs to unit timestamp
  const selectedDate = props.selectedDate;

  // multi unit
  const hasCartUnits = UnitsData?.hasCartUnits;

  // move unit
  const hasMoveUnit = UnitsData?.hasMoveUnit;

  const { loadUnitData } = props;
  const { unitNumber, unitId, unitType, unitStatus } = loadUnitData;

  const [unitData, setUnitData] = useState([]);
  const [loading, setLoading] = useState(false);

  let currentUnitStep = "Select Payment Method";
  if (hasCartUnits === true) currentUnitStep = "Select Equipment";
  if (UnitsData.thisUnit === unitId && hasCartUnits === true) {
    currentUnitStep = "Review Order";
  }

  const [unitStep, setUnitStep] = useState(currentUnitStep);

  // PAYMENT METHOD
  const [paymentMethod, setPaymentMethod] = useState("");

  // HOTEL INFO
  const [hotels, setHotels] = useState([]);
  const [hotelRoomResults, setHotelRoomResults] = useState([]);
  const [hotelName, setHotelName] = useState("");
  const [hotelLastName, setHotelLastName] = useState("");
  const [hotelRoomNumber, setHotelRoomNumber] = useState("");
  const [hotelManualEntry, setHotelManualEntry] = useState(0);
  const [hyattExtraCharge, setHyattExtraCharge] = useState(0);
  const [wyndhamExtraCharge, setWyndhamExtraCharge] = useState(0);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // Selectable Equipment
  const [selectedEquipment, setSelectedEquipment] = useState("");
  const [selectedAddOns, setSelectedAddOns] = useState("");

  // GUEST NAME
  const [guestName, setGuestName] = useState("");

  // TOTALS
  const [addOnsTotal, setAddOnsTotal] = useState(0);
  const [retailTotal, setRetailTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);

  // RECEIPT
  const [receiptType, setReceiptType] = useState([]);
  const [receiptEmail, setReceiptEmail] = useState("");
  const [receiptPhone, setReceiptPhone] = useState("");

  const [androidMessage, setAndroidMessage] = useState("");

  let selectedTab = "Unit Number";
  if (
    unitType !== null &&
    unitType !== "" &&
    unitType !== "P" &&
    unitType !== "T"
  ) {
    if (!unitStatus || unitStatus === "") {
      selectedTab = "Unit Number";
    }
    if (unitStatus === 1) {
      selectedTab = "New Customer";
    }
    if (unitStatus === 2) {
      selectedTab = "Customers";
    }
    if (unitStatus === 3) {
      selectedTab = "New Customer";
    }
    if (unitStatus === 4) {
      selectedTab = "Customers";
    }
    if (unitStatus === 5) {
      selectedTab = "New Customer";
    }
    if (unitStatus === 7) {
      selectedTab = "New Customer";
    }
    if (unitStatus === 8) {
      selectedTab = "Notes";
    }
  }
  const [tabKey] = useState(selectedTab);

  const logAction = useCallback(
    (logText) => {
      props.logAction(unitId, logText);
    },
    [props, unitId],
  );

  const updateUnitStep = useCallback((step) => {
    setUnitStep(step);
  }, []);

  const unitsContextValue = useMemo(
    () => ({
      retailTotal,
      setRetailTotal,
      subTotal,
      setSubTotal,
      guestName,
      setGuestName,
      selectedEquipment,
      setSelectedEquipment,
      selectedAddOns,
      setSelectedAddOns,
      addOnsTotal,
      setAddOnsTotal,
      orderTotal,
      setOrderTotal,
      unitStep,
      unitId,
      unitData,
      updateUnitStep,
      hotels,
      receiptType,
      receiptEmail,
      receiptPhone,
      setReceiptType,
      setReceiptEmail,
      setReceiptPhone,
      setHotels,
      hotelName,
      hotelManualEntry,
      setHotelName,
      hotelRoomResults,
      setHotelRoomResults,
      hotelLastName,
      hotelRoomNumber,
      androidMessage,
      paymentMethod,
      setPaymentMethod,
      setUnitStep,
      setHotelLastName,
      setHotelRoomNumber,
      setHotelManualEntry,
      logAction,
      setAndroidMessage,
      hyattExtraCharge,
      wyndhamExtraCharge,
      setHyattExtraCharge,
      setWyndhamExtraCharge,
      closeModal: props.closeModal,
      getUnits: props.updateUnitInfo,
    }),
    [
      retailTotal,
      setRetailTotal,
      subTotal,
      setSubTotal,
      guestName,
      setGuestName,
      selectedEquipment,
      setSelectedEquipment,
      selectedAddOns,
      setSelectedAddOns,
      addOnsTotal,
      setAddOnsTotal,
      orderTotal,
      setOrderTotal,
      unitStep,
      unitId,
      unitData,
      updateUnitStep,
      hotels,
      receiptType,
      receiptEmail,
      receiptPhone,
      setReceiptType,
      setReceiptEmail,
      setReceiptPhone,
      setHotels,
      hotelName,
      hotelManualEntry,
      setHotelName,
      hotelRoomResults,
      setHotelRoomResults,
      hotelLastName,
      hotelRoomNumber,
      androidMessage,
      paymentMethod,
      setPaymentMethod,
      setUnitStep,
      setHotelLastName,
      setHotelRoomNumber,
      setHotelManualEntry,
      logAction,
      setAndroidMessage,
      hyattExtraCharge,
      wyndhamExtraCharge,
      setHyattExtraCharge,
      setWyndhamExtraCharge,
      props.closeModal,
      props.updateUnitInfo,
    ],
  );

  useEffect(() => {
    setLoading(false);
    if (props.unitData.status) {
      setUnitData(props.unitData);
    }
  }, [props.unitData]);

  useEffect(() => {
    if (unitId) {
      props.getUnit(unitId, selectedDate);
      setLoading(true);
    }
  }, []);

  if (loading) {
    return (
      <div className="container mb-4">
        <UnitModalHeader unitData={unitData} closeModal={props.closeModal} />
        <div className="row">
          <div className="col-12">
            <LogoLoading />
          </div>
        </div>
      </div>
    );
  }

  if (hasMoveUnit) {
    return (
      <UnitPurchaseContext.Provider value={unitsContextValue}>
        <div className="container mb-4">
          <UnitModalHeader unitData={unitData} closeModal={props.closeModal} />
          <div className="row">
            <div className="col-12">
              <TabMoveCustomer unitData={unitData} />
            </div>
          </div>
        </div>
      </UnitPurchaseContext.Provider>
    );
  }

  let showUnitNumber = true;
  if (unitsContextValue.unitData.customers?.length > 0) {
    showUnitNumber = false;
  }

  let showNewCustomer = true;
  if (
    unitType !== "T" &&
    unitType !== "P" &&
    unitType !== null &&
    (unitStatus === 2 || unitStatus === 4 || unitStatus === 0)
  ) {
    showNewCustomer = false;
  }

  let showCustomers = false;
  if (
    (unitType !== "T" &&
      unitType !== "P" &&
      (userInfo.userType === "A" ||
        userInfo.userType === "S" ||
        userInfo.userType === "M" ||
        userInfo.userType === "O") &&
      unitData?.customers?.length !== 0) ||
    unitStatus === 2 ||
    unitStatus === 8 ||
    unitStatus === 4
  ) {
    showCustomers = true;
  }

  let showNotes = false;
  if (unitType !== "T" && unitType !== "P" && unitStatus !== 0) {
    showNotes = true;
  }

  let hideTabs = false;
  if (unitStep !== "Select Payment Method") {
    hideTabs = true;
  }
  let hideHeader = false;
  if (unitStep === "Select Tip") {
    hideHeader = true;
  }

  return (
    <UnitPurchaseContext.Provider value={unitsContextValue}>
      <div className="mb-4">
        <UnitModalHeader
          unitData={unitData}
          closeModal={props.closeModal}
          hideHeader={hideHeader}
        />
        <div className="row">
          <div className="col-12">
            <Tabs
              defaultActiveKey={tabKey}
              onSelect={(key) => {
                logAction(`Viewed ${key} Tab`);
              }}
              fill
              className={"mx-4"}
              style={{ backgroundColor: "#000" }}
            >
              {showUnitNumber && (
                <Tab
                  eventKey="Unit Number"
                  title="Unit Number"
                  mountOnEnter={true}
                  unmountOnExit={true}
                  tabClassName={hideTabs ? "d-none" : ""}
                >
                  <TabNumber
                    unitId={unitId}
                    unitType={unitType}
                    unitNumber={unitNumber}
                    closeModal={props.closeModal}
                    updateUnitInfo={props.updateUnitInfo}
                  />
                </Tab>
              )}

              {showNewCustomer && (
                <Tab
                  eventKey="New Customer"
                  title="New Customer"
                  mountOnEnter={true}
                  unmountOnExit={true}
                  tabClassName={hideTabs ? "d-none" : ""}
                >
                  <TabNewCustomer closeModal={props.closeModal} />
                </Tab>
              )}

              {showCustomers && (
                <Tab
                  eventKey="Customers"
                  title="Customers"
                  mountOnEnter={true}
                  unmountOnExit={true}
                  tabClassName={hideTabs ? "d-none" : ""}
                >
                  <TabCustomerList />
                </Tab>
              )}

              {(userInfo.userType === "A" ||
                userInfo.userType === "S" ||
                userInfo.userType === "M" ||
                userInfo.userType === "O") && (
                <Tab
                  eventKey="Log"
                  title="Log"
                  mountOnEnter={true}
                  unmountOnExit={true}
                  tabClassName={hideTabs ? "d-none" : ""}
                  disabled={
                    unitType === "T" || unitType === "P" || unitType === null
                  }
                >
                  <TabLog />
                </Tab>
              )}

              {showNotes && (
                <Tab
                  eventKey="Notes"
                  title="Notes"
                  mountOnEnter={true}
                  unmountOnExit={true}
                  tabClassName={hideTabs ? "d-none" : ""}
                  disabled={
                    unitType === "T" || unitType === "P" || unitType === null
                  }
                >
                  <TabNotes />
                </Tab>
              )}
            </Tabs>
          </div>
        </div>
      </div>
    </UnitPurchaseContext.Provider>
  );
};

const UnitModalHeader = ({ unitData, closeModal, hideHeader }) => {
  return (
    <div className="row">
      {unitData && !hideHeader && (
        <div className="col">
          <h5 className="mt-3 text-light">
            Loc: {unitData.umbrellaLocation} - Row:{" "}
            {parseInt(unitData.umbrellaRow) + 1} - Pos:{" "}
            {unitData.umbrellaPosition}
            {unitData.umbrellaNumber !== 0 &&
              unitData.umbrellaNumber !== "0" && (
                <> - #: {unitData.umbrellaNumber}</>
              )}
            - Status : {unitData.status}{" "}
            {unitData.status === "1" && "Available"}
            {unitData.status === "2" && "Rented"}
            {unitData.status === "3" && "Available"}
            {unitData.status === "4" && "Rented"}
            {unitData.status === "5" && "Occupied"}
            {unitData.status === "7" && "Occupied"}
            {unitData.status === "8" && "Needs Attention"}
          </h5>
        </div>
      )}
      <div className="col my-2 text-end">
        <button
          className="btn btn-danger btn-lg oswald mr-3"
          onClick={() => closeModal()}
        >
          <i className="fas fa-times" />
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state,
  unitData: state.units.unitData,
});

export default connect(mapStateToProps, {
  logAction,
  getUnit,
})(UnitModal);
