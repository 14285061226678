import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import AppTemplate from "./components/layout/AppTemplate";
import Dashboard from "./components/pages/dashboard/Dashboard";
import AuthTemplate from "./components/layout/AuthTemplate";
import Login from "./components/pages/Account/Login";
import Receipt from "./components/pages/Receipt/Receipt";
import Error404 from "./components/pages/Account/Error404";
import React from "react";

export const appRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route
        index
        element={
          <AppTemplate>
            <Dashboard />
          </AppTemplate>
        }
      />
      <Route path="auth" element={<AuthTemplate />}>
        <Route exact path="login" element={<Login />} />
        <Route exact path="logout" element={<Login />} />
      </Route>

      <Route path="dashboard" element={<AppTemplate />}>
        <Route index element={<Dashboard />} />
        <Route exact path=":unitView/:timeStamp" element={<Dashboard />} />
        <Route
          exact
          path=":unitView/:tabView/:timeStamp"
          element={<Dashboard />}
        />
      </Route>

      <Route exact path="receipt/:receiptId" element={<Receipt />} />
      <Route element={<Error404 />} />
    </Route>,
  ),
  {
    basename: "/",
  },
);
