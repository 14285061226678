import {
  GET_UNITS,
  GET_UNIT,
  GET_DATA,
  SAVE_CUSTOMER,
  SEARCH_WYNDHAM,
  MARK_EMPTY,
  SAVE_NOTE,
  GET_PAGE_DROPOFF,
  SAVE_DROPOFF,
  GET_CONFIG,
  SAVE_CONFIG,
  GET_PEOPLE_LIST,
  GET_PEOPLE,
  SAVE_PEOPLE,
  SAVE_PEOPLE_ERROR,
  DELETE_PEOPLE,
  DELETE_PEOPLE_ERROR,
  GET_LOCATION_LIST,
  GET_LOCATION,
  SAVE_LOCATION,
  SAVE_LOCATION_ERROR,
  DELETE_LOCATION,
  DELETE_LOCATION_ERROR,
  GET_PRICE_LIST,
  GET_PRICE,
  SAVE_PRICE,
  SAVE_PRICE_ERROR,
  DELETE_PRICE_ERROR,
  DELETE_PRICE,
  GET_HOTEL_LIST,
  GET_HOTEL,
  SAVE_HOTEL,
  SAVE_HOTEL_ERROR,
  DELETE_HOTEL_ERROR,
  DELETE_HOTEL,
  PROCESS_CC,
  DELETE_TRANSACTION,
  LOOKUP_TRANSACTION,
  GET_REPORT,
  RESEND_RECEIPT,
  UPDATE_RECEIPT,
  SAVE_EMAIL_ADDRESS,
  SAVE_EMAIL_ADDRESS_ERROR,
  LOGIN_FAIL,
  GET_RECEIPT,
} from "./types";
import axios from "axios";

import { ApiURL } from "../../config";
import { returnErrors } from "./messages";

export const logAction = (unitId, logText) => async (dispatch) => {
  try {
    let res = await axios.post(
      `${ApiURL}/logAction`,
      { unitId: unitId, logText: "LOG: " + logText },
      tokenConfig(),
    );
    dispatch({
      type: SAVE_NOTE,
      payload: res.data,
    });
  } catch (err) {
    console.log("logAction: ", err);
  }
};

export const getUnits =
  (dateStamp, searchTerm = "") =>
  async (dispatch) => {
    try {
      let res = null;
      if (dateStamp === "" || dateStamp === null) {
        res = await axios.get(`${ApiURL}/getUnits`, {
          ...tokenConfig(),
          params: {
            searchTerm: searchTerm,
          },
        });
      } else {
        res = await axios.get(`${ApiURL}/getUnits/${dateStamp}`, {
          ...tokenConfig(),
          params: {
            searchTerm: searchTerm,
          },
        });
      }
      dispatch({
        type: GET_UNITS,
        payload: res.data,
      });
    } catch (err) {
      console.log("getUnits Errors: ", err);
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOGIN_FAIL });
    }
  };
export const getReceipt = (receiptId) => async (dispatch) => {
  try {
    let res = await axios.get(
      `${ApiURL}/getReceipt/${receiptId}`,
      tokenConfig(),
    );
    dispatch({
      type: GET_RECEIPT,
      payload: res.data,
    });
  } catch (err) {
    console.log("getUnits Errors: ", err);
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const performSearch = (dateStamp) => async (dispatch) => {
  try {
    let res = null;
    if (dateStamp === "" || dateStamp === null) {
      res = await axios.get(`${ApiURL}/getUnits`, tokenConfig());
    } else {
      res = await axios.get(`${ApiURL}/getUnits/${dateStamp}`, tokenConfig());
    }
    dispatch({
      type: GET_UNITS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const getAllUnits = (dateStamp) => async (dispatch) => {
  try {
    let res = null;
    if (dateStamp === "" || dateStamp === null) {
      res = await axios.get(`${ApiURL}/getUnits/all`, tokenConfig());
    } else {
      res = await axios.get(
        `${ApiURL}/getUnits/all/${dateStamp}`,
        tokenConfig(),
      );
    }
    dispatch({
      type: GET_UNITS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const getUnit = (unitId, timeStamp) => async (dispatch) => {
  try {
    let res = null;
    if (timeStamp === "" || timeStamp === null) {
      res = await axios.get(`${ApiURL}/getUnit/${unitId}`, tokenConfig());
    } else {
      res = await axios.get(
        `${ApiURL}/getUnit/${unitId}/${timeStamp}`,
        tokenConfig(),
      );
    }
    dispatch({
      type: GET_UNIT,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const saveUnitNote =
  (unitId, customNotes, needsAttention) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${ApiURL}/saveUnitNotes/${unitId}`,
        { customNotes, needsAttention },
        tokenConfig(),
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (e) {
      console.log("e", e);
    }
  };
export const saveUnitNumber =
  (unitId, unitType, unitNumber) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${ApiURL}/saveUnitNumber`,
        { unitId, unitType, unitNumber },
        tokenConfig(),
      );
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOGIN_FAIL });
    }
  };
export const saveCustomer = (customerData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/saveCustomer`,
      { customerData: customerData },
      tokenConfig(),
    );
    dispatch({
      type: SAVE_CUSTOMER,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const moveCustomer = (newUnitData, oldUnitData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/moveCustomer`,
      {
        newUnitData,
        oldUnitData,
      },
      tokenConfig(),
    );
    dispatch({
      type: SAVE_CUSTOMER,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const searchWyndham = (customerData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/searchWyndham`,
      { customerData: customerData },
      tokenConfig(),
    );
    dispatch({
      type: SEARCH_WYNDHAM,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const searchHyatt = (customerData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/searchHyatt`,
      { customerData: customerData },
      tokenConfig(),
    );
    dispatch({
      type: SEARCH_WYNDHAM,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};

export const markOccupied = (unitData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/markOccupied`,
      { unitData: unitData },
      tokenConfig(),
    );
    dispatch({
      type: MARK_EMPTY,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const markOccupiedFlashing = (unitData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/markOccupiedFlashing`,
      { unitData: unitData },
      tokenConfig(),
    );
    dispatch({
      type: MARK_EMPTY,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const markOccupiedEmpty = (unitData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/markOccupiedEmpty`,
      { unitData: unitData },
      tokenConfig(),
    );
    dispatch({
      type: MARK_EMPTY,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const reseatCustomer = (unitData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/reseatCustomer`,
      { unitData: unitData },
      tokenConfig(),
    );

    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};

export const confirmCustomer = (customerId) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/confirmCustomer`,
      { customerId },
      tokenConfig(),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};

export const getDropoffReport = () => async (dispatch) => {
  try {
    const res = await axios.get(`${ApiURL}/getDropoffReport`, tokenConfig());
    dispatch({
      type: GET_PAGE_DROPOFF,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const saveDropoffNotes =
  (dropoffAmount, dropoffNotes) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${ApiURL}/saveDropoffNotes`,
        { dropoffAmount: dropoffAmount, dropoffNotes: dropoffNotes },
        tokenConfig(),
      );
      dispatch({
        type: SAVE_DROPOFF,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOGIN_FAIL });
    }
  };

export const saveConfigSettings =
  (
    fromName,
    fromEmail,
    paymentSubject,
    reportEmailTime,
    reportEmailSubject,
    reportEmailMessage,
    receiptTextMessage,
    receiptEmailSubject,
    receiptEmailBody,
  ) =>
  async (dispatch) => {
    try {
      const res = await axios.post(
        `${ApiURL}/saveConfigSettings`,
        {
          fromName,
          fromEmail,
          paymentSubject,
          reportEmailTime,
          reportEmailSubject,
          reportEmailMessage,
          receiptTextMessage,
          receiptEmailSubject,
          receiptEmailBody,
        },
        tokenConfig(),
      );
      dispatch({
        type: SAVE_CONFIG,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOGIN_FAIL });
    }
  };

export const getConfigSettings = () => async (dispatch) => {
  try {
    const res = await axios.get(`${ApiURL}/getConfigSettings`, tokenConfig());
    dispatch({
      type: GET_CONFIG,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const processCC =
  (orderTotal, addonTotal, formatID, ksn, encTracks, ourTransactionId) =>
  async (dispatch) => {
    try {
      const res = await axios.post(
        `${ApiURL}/processCC`,
        { orderTotal, addonTotal, formatID, ksn, encTracks, ourTransactionId },
        tokenConfig(),
      );
      dispatch({
        type: PROCESS_CC,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOGIN_FAIL });
    }
  };

export const getPeopleList = () => async (dispatch) => {
  try {
    const res = await axios.get(`${ApiURL}/getPeopleList`, tokenConfig());
    dispatch({
      type: GET_PEOPLE_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const getPeople = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${ApiURL}/getPeopleList/${userId}`,
      tokenConfig(),
    );
    dispatch({
      type: GET_PEOPLE,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const savePeople = (postData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/savePeople`,
      { postData },
      tokenConfig(),
    );
    dispatch({ type: SAVE_PEOPLE, payload: res.data });
  } catch (err) {
    dispatch({ type: SAVE_PEOPLE_ERROR, payload: err.response });
    dispatch({ type: LOGIN_FAIL });
  }
};
export const deletePeople = (postData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/deletePeople`,
      { postData },
      tokenConfig(),
    );
    dispatch({ type: DELETE_PEOPLE, payload: res.data });
  } catch (err) {
    dispatch({ type: DELETE_PEOPLE_ERROR, payload: err.response });
    dispatch({ type: LOGIN_FAIL });
  }
};

export const getLocationList = () => async (dispatch) => {
  try {
    const res = await axios.get(`${ApiURL}/getLocationList`, tokenConfig());
    dispatch({
      type: GET_LOCATION_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const getLocation = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${ApiURL}/getLocationList/${userId}`,
      tokenConfig(),
    );
    dispatch({
      type: GET_LOCATION,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const saveLocation = (postData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/saveLocation`,
      { postData },
      tokenConfig(),
    );
    dispatch({ type: SAVE_LOCATION, payload: res.data });
  } catch (err) {
    dispatch({ type: SAVE_LOCATION_ERROR, payload: err.response });
    dispatch({ type: LOGIN_FAIL });
  }
};
export const deleteLocation = (postData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/deleteLocation`,
      { postData },
      tokenConfig(),
    );
    dispatch({ type: DELETE_LOCATION, payload: res.data });
  } catch (err) {
    dispatch({ type: DELETE_LOCATION_ERROR, payload: err.response });
    dispatch({ type: LOGIN_FAIL });
  }
};

export const getConfigReportList = () => async (dispatch) => {
  try {
    const res = await axios.get(`${ApiURL}/getReportList`, tokenConfig());
    dispatch({
      type: GET_PRICE_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const getConfigReport = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${ApiURL}/getReportList/${id}`, tokenConfig());
    dispatch({
      type: GET_PRICE,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const saveConfigReport = (postData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/saveReportList`,
      { postData },
      tokenConfig(),
    );
    dispatch({ type: GET_PRICE, payload: res.data });
  } catch (err) {
    dispatch({ type: SAVE_PRICE_ERROR, payload: err.response });
    dispatch({ type: LOGIN_FAIL });
  }
};

export const getPriceList = () => async (dispatch) => {
  try {
    const res = await axios.get(`${ApiURL}/getPriceList`, tokenConfig());
    dispatch({
      type: GET_PRICE_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const getPrice = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${ApiURL}/getPriceList/${userId}`,
      tokenConfig(),
    );
    dispatch({
      type: GET_PRICE,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const savePrice = (postData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/savePrice`,
      { postData },
      tokenConfig(),
    );
    dispatch({ type: SAVE_PRICE, payload: res.data });
  } catch (err) {
    dispatch({ type: SAVE_PRICE_ERROR, payload: err.response });
    dispatch({ type: LOGIN_FAIL });
  }
};
export const deletePrice = (postData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/deletePrice`,
      { postData },
      tokenConfig(),
    );
    dispatch({ type: DELETE_PRICE, payload: res.data });
  } catch (err) {
    dispatch({ type: DELETE_PRICE_ERROR, payload: err.response });
    dispatch({ type: LOGIN_FAIL });
  }
};

export const getHotelList = () => async (dispatch) => {
  try {
    const res = await axios.get(`${ApiURL}/getHotelList`, tokenConfig());
    dispatch({
      type: GET_HOTEL_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const getHotel = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${ApiURL}/getHotelList/${userId}`,
      tokenConfig(),
    );
    dispatch({
      type: GET_HOTEL,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const saveHotel = (postData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/saveHotel`,
      { postData },
      tokenConfig(),
    );
    dispatch({ type: SAVE_HOTEL, payload: res.data });
  } catch (err) {
    dispatch({ type: SAVE_HOTEL_ERROR, payload: err.response });
    dispatch({ type: LOGIN_FAIL });
  }
};
export const deleteHotel = (postData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/deleteHotel`,
      { postData },
      tokenConfig(),
    );
    dispatch({ type: DELETE_HOTEL, payload: res.data });
  } catch (err) {
    dispatch({ type: DELETE_HOTEL_ERROR, payload: err.response });
    dispatch({ type: LOGIN_FAIL });
  }
};

export const getLogDetailsList = () => async (dispatch) => {
  try {
    const res = await axios.get(`${ApiURL}/getLogDetailsList`, tokenConfig());
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};

export const purgeDB = (purgeUntil) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${ApiURL}/purgeDB/${purgeUntil}`,
      tokenConfig(),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const getLogDetails = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${ApiURL}/getLogDetailsList/${id}`,
      tokenConfig(),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const saveLogDetails = (postData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/saveLogDetails`,
      { postData },
      tokenConfig(),
    );
    dispatch({ type: GET_DATA, payload: res.data });
  } catch (err) {
    dispatch({ type: SAVE_HOTEL_ERROR, payload: err.response });
    dispatch({ type: LOGIN_FAIL });
  }
};
export const deleteLogDetails = (postData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/deleteLogDetails`,
      { postData },
      tokenConfig(),
    );
    dispatch({ type: GET_DATA, payload: res.data });
  } catch (err) {
    dispatch({ type: DELETE_HOTEL_ERROR, payload: err.response });
    dispatch({ type: LOGIN_FAIL });
  }
};

export const getDailyLogList = () => async (dispatch) => {
  try {
    const res = await axios.get(`${ApiURL}/getDailyLogList`, tokenConfig());
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const getDailyLog = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${ApiURL}/getDailyLogList/${id}`,
      tokenConfig(),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const saveDailyLog = (id, logDate, data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/saveDailyLog`,
      { id, logDate, data },
      tokenConfig(),
    );
    dispatch({ type: GET_DATA, payload: res.data });
  } catch (err) {
    dispatch({ type: SAVE_HOTEL_ERROR, payload: err.response });
    dispatch({ type: LOGIN_FAIL });
  }
};
export const deleteDailyLog = (postData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/deleteDailyLog`,
      { postData },
      tokenConfig(),
    );
    dispatch({ type: GET_DATA, payload: res.data });
  } catch (err) {
    dispatch({ type: DELETE_HOTEL_ERROR, payload: err.response });
    dispatch({ type: LOGIN_FAIL });
  }
};

export const getPrintReceipt = (unitId, reprint) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/printReceipt`,
      { unitId: unitId, reprint: reprint },
      tokenConfig(),
    );
    if (window.Android) {
      dispatch(window.Android.PrintReceipt(res.data.PrintCode));
    } else {
      //dispatch(null);
    }
  } catch (err) {
    console.log(err);
  }
};
export const checkReceiptInfo =
  (receiptType, receiptPhone, receiptEmail) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${ApiURL}/checkReceiptInfo`,
        { receiptType, receiptPhone, receiptEmail },
        tokenConfig(),
      );
      dispatch({
        type: RESEND_RECEIPT,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
export const checkItemPrice =
  (itemType, extraCharge, roomChargeHotel) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${ApiURL}/checkItemPrice`,
        { itemType, extraCharge, roomChargeHotel },
        tokenConfig(),
      );
      dispatch({
        type: RESEND_RECEIPT,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
export const resendReceipt = (customerId, reprint) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/resendReceipt`,
      { customerId, reprint },
      tokenConfig(),
    );
    dispatch({
      type: RESEND_RECEIPT,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const updateReceipt =
  (unitId, customerId, receiptType, receiptPhone, receiptEmail) =>
  async (dispatch) => {
    try {
      const res = await axios.post(
        `${ApiURL}/updateReceipt`,
        { unitId, customerId, receiptType, receiptPhone, receiptEmail },
        tokenConfig(),
      );
      dispatch({
        type: UPDATE_RECEIPT,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const getPrintUnitNote = (noteId) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/printUnitNotes`,
      { noteId: noteId },
      tokenConfig(),
    );
    dispatch(window.Android.PrintReceipt(res.data.PrintCode));
  } catch (err) {
    console.log(err);
  }
};
export const getPrintNotes = () => async (dispatch) => {
  try {
    const res = await axios.get(`${ApiURL}/getPrintNotes`, tokenConfig());
    dispatch(window.Android.PrintReceipt(res.data.PrintCode));
  } catch (err) {
    console.log(err);
  }
};

export const deleteTransaction =
  (deleteId, reportTime, reportType) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${ApiURL}/deleteTransaction`,
        { deleteId: deleteId, reportTime: reportTime, reportType: reportType },
        tokenConfig(),
      );
      dispatch({
        type: DELETE_TRANSACTION,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOGIN_FAIL });
    }
  };
export const deleteMultiTransaction =
  (deleteIds, reportTime) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${ApiURL}/deleteMultiTransaction`,
        { deleteIds: deleteIds, reportTime: reportTime },
        tokenConfig(),
      );
      dispatch({
        type: DELETE_TRANSACTION,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOGIN_FAIL });
    }
  };
export const deleteTipMultiTransaction =
  (deleteIds, reportTime) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${ApiURL}/deleteTipMultiTransaction`,
        { deleteIds: deleteIds, reportTime: reportTime },
        tokenConfig(),
      );
      dispatch({
        type: DELETE_TRANSACTION,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOGIN_FAIL });
    }
  };
export const switchTransaction =
  (customerId, reportType) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${ApiURL}/switchTransaction`,
        { customerId: customerId, reportType: reportType },
        tokenConfig(),
      );
      dispatch({
        type: GET_REPORT,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOGIN_FAIL });
    }
  };
export const saveTransaction =
  (price, transactionId, reportType, reportTime) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${ApiURL}/saveTransaction`,
        {
          price: price,
          transactionId: transactionId,
          reportType: reportType,
          reportTime: reportTime,
        },
        tokenConfig(),
      );
      dispatch({
        type: GET_REPORT,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOGIN_FAIL });
    }
  };
export const getTip = (transactionId) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/getTip`,
      { transactionId },
      tokenConfig(),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const saveTip = (tip, transactionId) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/saveTip`,
      { tip, transactionId },
      tokenConfig(),
    );
    dispatch({
      type: GET_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};

export const lookupTransaction = (viewTransactionId) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${ApiURL}/lookupTransaction`,
      { viewTransactionId: viewTransactionId },
      tokenConfig(),
    );
    dispatch({
      type: LOOKUP_TRANSACTION,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};

export const getReport = (reportType, reportTime) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${ApiURL}/getReport/${reportType}/${reportTime}`,
      tokenConfig(),
    );
    dispatch({
      type: GET_REPORT,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const sendEmailReport = (reportType, reportTime) => async (dispatch) => {
  try {
    const res = axios.get(
      `${ApiURL}/sendEmailReport/${reportType}/${reportTime}`,
      tokenConfig(),
    );
    dispatch({
      type: GET_REPORT,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err, err.response));
    dispatch({ type: LOGIN_FAIL });
  }
};
export const searchReport =
  (reportType, startDate, endDate) => async (dispatch) => {
    let newStartDate = new Date(startDate);
    let outputStartDate = newStartDate.getTime();

    let newEndDate = new Date(endDate);
    let outputEndDate = newEndDate.getTime();

    try {
      const res = await axios.get(
        `${ApiURL}/getSearchReport/${reportType}/${outputStartDate}/${outputEndDate}`,
        tokenConfig(),
      );
      dispatch({
        type: GET_REPORT,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({ type: LOGIN_FAIL });
    }
  };
export const saveEmailAddresses =
  (emailAddresses, reportType) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${ApiURL}/saveEmailAddresses`,
        { emailAddresses, reportType },
        tokenConfig(),
      );
      dispatch({ type: SAVE_EMAIL_ADDRESS, payload: res.data });
    } catch (err) {
      dispatch({ type: SAVE_EMAIL_ADDRESS_ERROR, payload: err.response });
      dispatch({ type: LOGIN_FAIL });
    }
  };

export const tokenConfig = () => {
  // GET TOKEN FROM STATE

  const token = localStorage.getItem("authToken");

  // HEADERS
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  // IF TOKEN ADD TO HEADERS
  if (token) {
    config.headers["Authorization"] = token;
  }
  return config;
};
