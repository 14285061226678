import React from "react";

const WaterIcon = ({ showFlashing }) => {
  return (
    <i
      className={
        showFlashing
          ? "fas fa-water flashingTitle"
          : "fas fa-water text-primary"
      }
    />
  );
};
export default WaterIcon;
