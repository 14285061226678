export const compileNotes = (data) => {
  const {
    receiptType,
    receiptPhone,
    receiptEmail,

    orderSubTotal,
    orderRetailTotal,
    addonsTotal,
    unitsTotal,
    paymentMethod,
    guestName,
    hotelName,
    hotelLastName,
    hotelRoomNumber,
    hotelManualEntry,
    wyndhamExtraCharge,
    hyattExtraCharge,
    selectedEquipment,
    selectedAddOns,
    tipAmount,
    transactionID,

    hasCartUnits,
    cartUnits,
    currentUnit,
  } = data;
  let ourTransactionId = data.ourTransactionId;
  let tipType = data.tipType.replace("tip", "") + "%";

  let notes = "";
  notes += `Customer Name : ${guestName !== "" ? guestName : hotelLastName}\n`;
  notes += "Payment Method : " + paymentMethod + "\n";
  notes += "Receipt Type : " + receiptType + "\n";
  if (receiptType === "phone")
    notes += "Receipt Phone : " + receiptPhone + "\n";
  if (receiptType === "email")
    notes += "Receipt Email : " + receiptEmail + "\n";

  if (paymentMethod === "Room Charge") {
    notes += "Hotel : " + hotelName + "\n";
    notes += "Room # : " + hotelRoomNumber + "\n";
    notes += "Room Guest Name : " + hotelLastName + "\n";
    notes += "Manual Entry : " + hotelManualEntry + "\n";

    if (wyndhamExtraCharge) {
      notes += "Additional Wyndham Rental : " + wyndhamExtraCharge + "\n";
    }
    if (hyattExtraCharge) {
      notes += "Additional Hyatt Rental : " + hyattExtraCharge + "\n";
    }
  }
  notes += "---------------------------\n";

  if (hasCartUnits) {
    ourTransactionId = currentUnit.unitData.ourTransactionId;
    // current unit
    notes +=
      "Unit 1 : " +
      currentUnit.unitData.umbrellaType +
      currentUnit.unitData.umbrellaNumber +
      "\n";
    notes +=
      "Unit 1 Price : $" +
      Number.parseFloat(currentUnit.subTotal).toFixed(2) +
      "\n";

    let selectedAddOns = [];
    if (
      currentUnit.selectedAddOns !== null &&
      typeof currentUnit.selectedAddOns !== "undefined"
    ) {
      selectedAddOns = currentUnit.selectedAddOns.split(",").filter((n) => n);
    }
    if (selectedAddOns.length > 0) {
      selectedAddOns.map((addon) => {
        currentUnit.unitData.equipmentData.addons.map((addonName, index2) => {
          if (addonName.unitType === addon) {
            notes += "Addon : " + addonName.name + "\n";
            notes +=
              "Addon Price : $" +
              Number.parseFloat(addonName.price).toFixed(2) +
              "\n";
          }
          return null;
        });
        return null;
      });
    }

    // other units in cart
    cartUnits.map((unit, index) => {
      notes += "---------------------------\n";
      let selectedAddOns = [];
      if (
        unit.selectedAddOns !== null &&
        typeof unit.selectedAddOns !== "undefined"
      ) {
        selectedAddOns = unit.selectedAddOns.split(",").filter((n) => n);
      }
      notes +=
        "Unit " +
        (index + 2) +
        " : " +
        unit.umbrellaType +
        unit.umbrellaNumber +
        "\n";
      notes +=
        "Unit " +
        (index + 2) +
        " Price : $" +
        Number.parseFloat(unit.subTotal).toFixed(2) +
        "\n";

      if (selectedAddOns.length > 0) {
        selectedAddOns.map((addon) => {
          currentUnit.unitData.equipmentData.addons.map((addonName, index2) => {
            if (addonName.unitType === addon) {
              notes += "Addon : " + addonName.name + "\n";
              notes +=
                "Addon Price : $" +
                Number.parseFloat(addonName.price).toFixed(2) +
                "\n";
            }
            return null;
          });
          return null;
        });
      }
      return null;
    });
  } else {
    notes += "Unit : " + selectedEquipment + "\n";
    notes += "Unit Price : $" + unitsTotal.toFixed(2) + "\n";
    if (selectedAddOns) {
      notes += "Addons : " + selectedAddOns + "\n";
      notes += "Addon Price : $" + addonsTotal.toFixed(2) + "\n";
    }
  }

  notes += "---------------------------\n";
  notes += "Retail Total : $" + parseFloat(orderRetailTotal).toFixed(2) + "\n";
  notes += "Sub Total : $" + orderSubTotal.toFixed(2) + "\n";
  notes += "Tip : $" + tipAmount.toFixed(2) + "\n";
  notes += "Tip Type : " + tipType + "\n";
  notes +=
    "Total : $" + parseFloat(orderSubTotal + tipAmount).toFixed(2) + "\n";

  notes += "---------------------------\n";
  if (paymentMethod === "Credit Card") {
    notes += "CC Transaction ID " + transactionID + "\n";
  }
  notes += "Our Transaction ID : " + ourTransactionId + "\n";
  notes += "Collector : " + ourTransactionId + "\n";

  return notes;
};
