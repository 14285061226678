import React, { useContext } from "react";
import { UnitPurchaseContext } from "../../UnitModal";

const SelectHotel = () => {
  const UnitsData = useContext(UnitPurchaseContext);

  const setHotelName = (hotelName) => {
    if (hotelName === "O Sands") {
      UnitsData.setHotelName("Opal Sands");
    } else if (hotelName === "W") {
      UnitsData.setHotelName("Wyndham");
    } else if (hotelName === "H") {
      UnitsData.setHotelName("Hyatt");
    } else {
      UnitsData.setHotelName(hotelName);
    }
    if (hotelName === "W") {
      UnitsData.updateUnitStep("Select Hotel Customer Name Wyndham");
    } else if (hotelName === "H") {
      UnitsData.updateUnitStep("Select Hotel Customer Name Hyatt");
    } else {
      UnitsData.updateUnitStep("Select Hotel Customer Name");
    }
  };

  return (
    <div className="row">
      <div className="col-sm-12 col-md-6 offset-md-3 text-center">
        <div className="d-grid gap-2 mb-5">
          <button
            className="btn btn-block btn-secondary btn-lg mt-4"
            onClick={() => setHotelName("W")}
          >
            WYNDHAM
          </button>
          <button
            className="btn btn-block btn-secondary btn-lg mt-4"
            onClick={() => setHotelName("H")}
          >
            HYATT
          </button>
          <button
            className="btn btn-block btn-secondary btn-lg mt-4"
            onClick={() => setHotelName("O Sands")}
          >
            OPAL SANDS
          </button>
          <button
            className="btn btn-block btn-secondary btn-lg mt-4"
            onClick={() => setHotelName("Hiatus CB")}
          >
            HIATUS CB
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectHotel;
