import React, { useContext, useEffect, useState } from "react";
import { UnitPurchaseContext } from "../../UnitModal";

const CustomerName = (props) => {
  const UnitPurchaseData = useContext(UnitPurchaseContext);
  const [guestName, setGuestName] = useState(UnitPurchaseData.guestName);

  useEffect(() => {
    if (window.location.hostname.indexOf("localhost") !== -1) {
      setGuestName("leblanc");
    }
  }, []);
  const saveGuestName = () => {
    UnitPurchaseData.setGuestName(guestName);
    UnitPurchaseData.setUnitStep("Review Order");
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 mt-4 text-center">
              <label htmlFor="lastName" className="form-label">
                Enter Customer Name
                <input
                  type="text"
                  name="lastName"
                  className="form-control form-control-lg"
                  id="lastName"
                  placeholder="enter customer name ..."
                  onChange={(e) => setGuestName(e.target.value)}
                  value={guestName}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 offset-md-3 text-center">
          <button
            className={`btn btn-block btn-${
              guestName ? "primary" : "secondary"
            } btn-lg mt-4`}
            onClick={() => saveGuestName()}
            disabled={!guestName}
          >
            CONTINUE
          </button>
        </div>
      </div>
    </>
  );
};

export default CustomerName;
