import React, { useContext, useEffect } from "react";
import UnitNav from "./TabNewCustomer/UnitNav";
import SelectPaymentMethod from "./TabNewCustomer/SelectPaymentMethod";
import SelectEquipment from "./TabNewCustomer/SelectEquipment";
import CustomerName from "./TabNewCustomer/CustomerName";
import ReviewOrder from "./TabNewCustomer/ReviewOrder";
import SelectTip from "./TabNewCustomer/SelectTip";
import SelectReceiptType from "./TabNewCustomer/SelectReceiptType";

import SelectHotel from "./TabNewCustomer/SelectHotel";
import SelectHotelCustomerName from "./TabNewCustomer/SelectHotel/SelectHotelCustomerName";
import SelectHotelCustomerNameHyatt from "./TabNewCustomer/SelectHotel/SelectHotelCustomerNameHyatt";
import SelectHotelCustomerNameWyndham from "./TabNewCustomer/SelectHotel/SelectHotelCustomerNameWyndham";
import { UnitPurchaseContext } from "../UnitModal";

const TabNewCustomer = (props) => {
  const UnitPurchaseData = useContext(UnitPurchaseContext);
  const unitStep = UnitPurchaseData.unitStep;

  useEffect(() => {
    UnitPurchaseData.logAction("Viewed " + unitStep);
  }, [unitStep]);

  switch (unitStep) {
    case "Select Payment Method":
      return (
        <div className="card panel-primary">
          <div className="card-header">Select Payment Method</div>
          <div className="card-body">
            <SelectPaymentMethod />
          </div>
        </div>
      );
    case "Select Hotel":
      return (
        <div className="card panel-primary">
          <div className="card-header">Select Hotel</div>
          <div className="card-body">
            <UnitNav />
            <SelectHotel />
          </div>
        </div>
      );
    case "Select Hotel Customer Name":
      return (
        <div className="card panel-primary">
          <div className="card-header">Select Hotel Customer Name</div>
          <div className="card-body">
            <UnitNav />
            <SelectHotelCustomerName />
          </div>
        </div>
      );
    case "Select Hotel Customer Name Hyatt":
      return (
        <div className="card panel-primary">
          <div className="card-header">Select Hyatt Customer Name</div>
          <div className="card-body">
            <UnitNav />
            <SelectHotelCustomerNameHyatt />
          </div>
        </div>
      );
    case "Select Hotel Customer Name Wyndham":
      return (
        <div className="card panel-primary">
          <div className="card-header">Select Wyndham Customer Name</div>
          <div className="card-body">
            <UnitNav />
            <SelectHotelCustomerNameWyndham />
          </div>
        </div>
      );

    case "Select Equipment":
      return (
        <div className="card panel-primary">
          <div className="card-header">Equipment</div>
          <div className="card-body">
            <UnitNav />
            <SelectEquipment />
          </div>
        </div>
      );
    case "Enter Customer Name":
      return (
        <div className="card panel-primary">
          <div className="card-header">Enter Customer Name</div>
          <div className="card-body">
            <UnitNav />
            <CustomerName />
          </div>
        </div>
      );
    case "Select Tip":
      return (
        <div className="card panel-primary">
          <div className="card-header">Select Tip</div>
          <div className="card-body">
            <SelectTip />
          </div>
        </div>
      );
    case "Select Receipt Type":
      return (
        <div className="card panel-primary">
          <div className="card-header">Select Receipt Type</div>
          <div className="card-body">
            <UnitNav />
            <SelectReceiptType />
          </div>
        </div>
      );
    case "Review Order":
      return (
        <div className="card panel-primary">
          <div className="card-header">Review Order</div>
          <div className="card-body">
            <UnitNav />
            <ReviewOrder />
          </div>
        </div>
      );
    default:
      return <>loading</>;
  }
};

export default TabNewCustomer;
