import React, { useContext, useEffect } from "react";
import Loading from "../../../../../layout/Loading";
import FormInput from "components/cmation-formsbuilder";
import { ApiURL } from "../../../../../../config";
import { UnitPurchaseContext } from "../../../UnitModal";

const SelectHotelCustomerNameHyatt = () => {
  const [hotelLastName, setHotelLastName] = React.useState("");
  const [hotelRoomNumber, setHotelRoomNumber] = React.useState("");
  const [hotelResults, setHotelResults] = React.useState({
    status: false,
    message: "",
    rooms: [],
  });
  const [loading, setLoading] = React.useState(false);
  const [hideButton, setHideButton] = React.useState(true);
  const authToken = localStorage.getItem("authToken");
  const UnitsPurchaseData = useContext(UnitPurchaseContext);

  const saveHyattGuestName = (roomNumber, lastName, additional) => {
    UnitsPurchaseData.setHotelRoomNumber(roomNumber);
    UnitsPurchaseData.setHotelLastName(lastName);
    UnitsPurchaseData.setHyattExtraCharge(additional);
    UnitsPurchaseData.updateUnitStep("Select Equipment");
  };

  const searchHotel = () => {
    if (hotelRoomNumber && hotelLastName) {
      setLoading(true);
      fetch(`${ApiURL}/searchHyatt`, {
        method: "POST",
        body: JSON.stringify({
          hotelLastName: hotelLastName,
          hotelRoomNumber: hotelRoomNumber,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        credentials: "include",
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (res) {
            setHotelResults(res);
            if (res.status === false) {
              setHideButton(true);
            }
            if (res.status === true) {
              setHideButton(false);
            }
          }
        })
        .catch((err) => {
          console.log("authLogin Error", err);
        });
    }
  };

  useEffect(() => {
    searchHotel();
  }, [hotelRoomNumber, hotelLastName]);

  return (
    <>
      <div className="row bg-light border-bottom border-top mt-3">
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1">Last Name</label>
            <FormInput
              type="text"
              className="form-control"
              id="hotelLastName"
              name="hotelLastName"
              placeholder="enter last name ..."
              onChange={(value) => {
                setHotelLastName(value);
              }}
              defaultValue={hotelLastName}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1">Room Number</label>
            <FormInput
              type="number"
              className="form-control"
              id="hotelRoomNumber"
              name="hotelRoomNumber"
              placeholder="enter room number ..."
              onChange={(value) => {
                setHotelRoomNumber(value);
              }}
              defaultValue={hotelRoomNumber}
            />
          </div>
        </div>
      </div>

      <table className="mt-3 table table-striped">
        <thead className="thead-dark text-light">
          <tr>
            <th width="20%">Room</th>
            <th width="60%">Name</th>
            <th width="20%">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {hotelResults &&
            (hotelResults.status === false ||
              hotelResults.rooms.length === 0) &&
            hotelResults.message && (
              <tr>
                <td colSpan="3" className="text-center">
                  <div className="alert alert-danger">
                    {hotelResults.message}
                  </div>
                </td>
              </tr>
            )}

          {hotelResults.status &&
            hotelResults?.rooms?.map((room, index) => {
              return (
                <tr key={index}>
                  <td>{room.room}</td>
                  <td>{room.guestName}</td>
                  <td nowrap="true">
                    {typeof room.room !== "undefined" && (
                      <>
                        {room.additional === 1 && (
                          <button
                            onClick={() =>
                              saveHyattGuestName(room.room, room.guestName, 1)
                            }
                            className="btn btn-success btn-sm"
                          >
                            SELECT
                          </button>
                        )}
                        {room.additional === 0 &&
                          room.room !== "" &&
                          typeof room.room !== "undefined" && (
                            <button
                              onClick={() =>
                                saveHyattGuestName(room.room, room.guestName, 0)
                              }
                              className="btn btn-primary btn-sm"
                            >
                              SELECT
                            </button>
                          )}
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      {loading && <Loading />}

      <div className="row justify-content-md-center mt-3">
        <div className="col-sm-12 col-md-6 text-center">
          <button
            className="btn btn-block btn-secondary btn-lg mt-4"
            onClick={() =>
              saveHyattGuestName(hotelRoomNumber, hotelLastName, 0)
            }
            disabled={
              hideButton || (hotelResults && hotelResults.status === false)
            }
          >
            CONTINUE
          </button>
        </div>
      </div>
    </>
  );
};

export default SelectHotelCustomerNameHyatt;
