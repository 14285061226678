import React, { useContext, useEffect } from "react";
import { UnitPurchaseContext } from "../../UnitModal";
import { UnitsContext } from "../../Dashboard";
import Items from "./ReviewOrder/Item";

const ReviewOrder = (props) => {
  const UnitsData = useContext(UnitsContext);
  const UnitPurchaseData = useContext(UnitPurchaseContext);
  let orderSubTotal = 0;
  let orderRetailTotal = 0;
  let addonsTotal = 0;
  let unitsTotal = 0;

  const saveOrderReview = () => {
    UnitPurchaseData.logAction("Saved Order Review");
    UnitPurchaseData.setUnitStep("Select Receipt Type");
  };

  useEffect(() => {
    UnitPurchaseData.logAction("Viewed Review Order");
  }, []);

  unitsTotal += Number.parseFloat(UnitPurchaseData.subTotal);
  addonsTotal += Number.parseFloat(UnitPurchaseData.addOnsTotal);
  orderSubTotal +=
    Number.parseFloat(UnitPurchaseData.subTotal) +
    Number.parseFloat(UnitPurchaseData.addOnsTotal);
  orderRetailTotal +=
    Number.parseFloat(UnitPurchaseData.retailTotal) +
    Number.parseFloat(UnitPurchaseData.addOnsTotal);

  let paymentMethod = "";
  let guestName = "";
  let hotelName = "";
  let hotelLastName = "";

  if (UnitsData.hasCartUnits) {
    paymentMethod = UnitsData.checkoutDetails.paymentMethod;
    guestName = UnitsData.checkoutDetails.guestName;
    hotelName = UnitsData.checkoutDetails.hotelName;
    hotelLastName = UnitsData.checkoutDetails.hotelLastName;

    UnitsData.cartUnits.forEach((item) => {
      unitsTotal += Number.parseFloat(item.subTotal);
      orderSubTotal +=
        Number.parseFloat(item.subTotal) + Number.parseFloat(item.addOnsTotal);
      orderRetailTotal +=
        Number.parseFloat(item.retailTotal) +
        Number.parseFloat(item.addOnsTotal);
      addonsTotal += Number.parseFloat(item.addOnsTotal);
    });

    UnitPurchaseData.paymentMethod = paymentMethod;
    UnitPurchaseData.guestName = guestName;
    UnitPurchaseData.hotelName = hotelName;
    UnitPurchaseData.hotelLastName = hotelLastName;
    UnitPurchaseData.hotelRoomNumber =
      UnitsData.checkoutDetails.hotelRoomNumber;
    UnitPurchaseData.hotelManualEntry =
      UnitsData.checkoutDetails.hotelManualEntry;
    UnitPurchaseData.wyndhamExtraCharge =
      UnitsData.checkoutDetails.wyndhamExtraCharge;
    UnitPurchaseData.hyattExtraCharge =
      UnitsData.checkoutDetails.hyattExtraCharge;
  } else {
    paymentMethod = UnitPurchaseData.paymentMethod;
    guestName = UnitPurchaseData.guestName;
    hotelName = UnitPurchaseData.hotelName;
    hotelLastName = UnitPurchaseData.hotelLastName;
  }
  UnitPurchaseData.unitsTotal = unitsTotal;
  UnitPurchaseData.addonsTotal = addonsTotal;
  UnitPurchaseData.orderSubTotal = orderSubTotal;
  UnitPurchaseData.orderRetailTotal = orderRetailTotal;

  return (
    <div className="row">
      <div className="col-sm-12 text-center">
        <div className="container">
          <div className="row my-2">
            <div className="col-12">
              <h5 className="text-center mt-2">
                Payment Method : {paymentMethod}
              </h5>
            </div>
          </div>
          {UnitPurchaseData.paymentMethod === "Room Charge" && (
            <>
              <div className="row my-2">
                <div className="col-12">
                  <h5 className="text-center mt-2">Hotel : {hotelName}</h5>
                </div>
              </div>
              <div className="row my-2">
                <div className="col-12">
                  <h5 className="text-center mt-2">
                    Guest Name : {hotelLastName}{" "}
                  </h5>
                </div>
              </div>
            </>
          )}
          {paymentMethod !== "Room Charge" && (
            <div className="row my-2">
              <div className="col-12">
                <h5 className="text-center mt-2">
                  Customer Name : {guestName}
                </h5>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-sm-12 col-md-6 offset-md-3 text-center">
              <table className="table table-sm table-striped table-bordered">
                <tbody>
                  {(!UnitsData.thisUnit ||
                    UnitsData.thisUnit !== UnitPurchaseData.unitId) && (
                    <Items
                      unitData={{
                        ...UnitPurchaseData.unitData,
                        selectedEquipment: UnitPurchaseData.selectedEquipment,
                        selectedAddOns: UnitPurchaseData.selectedAddOns,
                        subTotal: UnitPurchaseData.subTotal,
                      }}
                    />
                  )}
                  {UnitsData.hasCartUnits &&
                    UnitsData.cartUnits.map((item, index) => {
                      return <Items unitData={item} key={`item-${index}`} />;
                    })}
                  <tr>
                    <td colSpan="2" align="right">
                      Units Total
                    </td>
                    <td>${unitsTotal.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td colSpan="2" align="right">
                      Addons Total
                    </td>
                    <td>${addonsTotal.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td colSpan="2" align="right">
                      Charge Total
                    </td>
                    <td>${orderSubTotal.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td colSpan="2" align="right">
                      Retail Total
                    </td>
                    <td>${orderRetailTotal.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6 offset-md-3 text-center">
              <button
                className="btn btn-block btn-success btn-lg my-4"
                onClick={
                  () => saveOrderReview() // UnitsData.cartUnits
                }
              >
                CONTINUE
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6 offset-md-3 text-center">
              <button
                className="btn btn-block btn-warning btn-sm mt-4 mx-2"
                onClick={() => UnitsData.selectMultipleUnits(UnitPurchaseData)}
              >
                Add Additional Unit(s)
              </button>

              {UnitsData.hasCartUnits && (
                <button
                  className="btn btn-block btn-danger btn-sm mt-4 mx-2"
                  onClick={() =>
                    UnitsData.unSelectUnit(UnitPurchaseData.unitId)
                  }
                >
                  Remove from transaction
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewOrder;
