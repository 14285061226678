import React from "react";
import Unit from "./Unit";

// 134

const Units = ({ units, hasCartUnits, cartUnits, hasMoveUnit, moveUnit }) => {
  return (
    <>
      {units.map((row, rowIndex) => {
        if (row.length !== 0) {
          return (
            <div
              key={`row${rowIndex}`}
              className="units row"
              style={{
                width: `${row.length * 118}px`,
              }}
            >
              {row.map((unit, unitIndex) => {
                let extraStyle = "";
                if (hasCartUnits === true && unit.status !== 0) {
                  let cartUnitsFound = cartUnits.filter(
                    (filterUnit) => filterUnit.unitId === unit.id,
                  );
                  if (cartUnitsFound.length === 1) {
                    extraStyle = "inCart";
                  }
                }

                if (hasMoveUnit === true && unit.status !== 0) {
                  if (moveUnit.unitId === unit.id) {
                    extraStyle = "moveUnit";
                  } else {
                    extraStyle = "";
                  }
                }

                return (
                  <Unit
                    key={`unit${unitIndex}`}
                    index={unitIndex}
                    unit={unit}
                    locationId={unit.locationId}
                    extraStyle={extraStyle}
                  />
                );
              })}
            </div>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

export default Units;
