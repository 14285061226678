import React, { Component } from "react";
import loading from "../../assets/foot.png";
class LogoLoading extends Component {
  render() {
    return (
      <div className="text-center">
        <img
          src={loading}
          alt="Loading"
          className="logoSpin"
          style={{ width: 150 }}
        />
      </div>
    );
  }
}
export default LogoLoading;
