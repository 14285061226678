import React from "react";
import "react-toggle/style.css";
import Toggle from "react-toggle";
import InputError from "./InputError";
import InputHelpText from "./InputHelpText";

const FormInputToggle = (props) => {
  let defaultValue = props.defaultValue;
  if (props.value !== null && props.value !== "") defaultValue = props.value;

  defaultValue =
    defaultValue === 1 ||
    defaultValue === "1" ||
    defaultValue === "true" ||
    defaultValue === true;

  const changeValue = (e) => {
    let result = 1;
    if (e.target.checked === true) result = 1;
    if (e.target.checked === false) result = 0;
    props.onChange(result);
  };

  return (
    <div className={`col-${props.divColSize}`}>
      <div className="container p-0">
        <div className="form-group row border-bottom pb-2">
          <label htmlFor={props.name} className="col-sm-12">
            {props.label}
          </label>
          <div className="col-sm-12">
            <Toggle
              name={props.name}
              defaultChecked={defaultValue}
              defaultValue={defaultValue}
              icons={false}
              onChange={changeValue}
            />
          </div>

          <InputError error={props.error} />
          <InputHelpText helpText={props.helpText} />
        </div>
      </div>
    </div>
  );
};

export default FormInputToggle;
