import {
    GET_UNITS,
    GET_UNIT,
    MARK_EMPTY,
    RESEAT_CUSTOMER,
    SAVE_CUSTOMER,
    SAVE_UNIT_NUMBER,
    SAVE_NOTE,
    UPDATE_RECEIPT,
    RESEND_RECEIPT
} from "../actions/types";
const initialState = {
    data: {},
    unitData: {},
    notes:{}
};
export default function UnitsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_UNITS:
            return {
                ...state,
                data: action.payload
            };

        case SAVE_NOTE:
            return {
                ...state,
                notes: action.payload
            };

        case GET_UNIT:
        case MARK_EMPTY:
        case RESEAT_CUSTOMER:
        case SAVE_CUSTOMER:
        case SAVE_UNIT_NUMBER:
            return {
                ...state,
                unitData: action.payload
            };

        case RESEND_RECEIPT:
        case UPDATE_RECEIPT:
            return {
                ...state,
                receiptData: action.payload
            };

        default:
            return state;
    }
}
