import { SEARCH_WYNDHAM } from "../actions/types";
const initialState = {
  results: {},
  unitData: {},
  hotelResults: [],
};
export default function CustomersReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_WYNDHAM:
      if (action.payload.token) {
        localStorage.setItem("token", action.payload.token);
        localStorage.setItem("authToken", action.payload.token);
      }
      return {
        ...state,
        hotelResults: action.payload,
      };

    default:
      return state;
  }
}
