import { Modal as ModalWindow, ModalBody } from "reactstrap";
import React from "react";

const Modal = ({ children, className }) => {
  return (
    <ModalWindow
      isOpen={true}
      className={className}
      fullscreen={true}
      backdrop={false}
    >
      <ModalBody style={{ backgroundColor: "#000" }}>{children}</ModalBody>
    </ModalWindow>
  );
};
export default Modal;
