import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { checkReceiptInfo } from "client/actions/apiActions";
import { UnitPurchaseContext } from "../../UnitModal";
import ItemRadio from "components/form/itemRadio";
import FormInput from "components/cmation-formsbuilder";

const SelectReceiptType = (props) => {
  const [receiptType, setReceiptType] = useState(
    props.receiptType ? props.receiptType : "phone",
  );
  let phone = props.receiptPhone
    ?.replace("-", "")
    ?.replace("(", "")
    ?.replace(")", "")
    ?.replace(" ", "");
  if (window.location.hostname.indexOf("localhost") !== -1) {
    phone = 7276884005;
  }
  const [receiptPhone, setCustomerPhone] = useState(phone);
  const [receiptEmail, setCustomerEmail] = useState(props.receiptEmail);
  const [receiptErrorMessage, setReceiptErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const UnitPurchaseData = useContext(UnitPurchaseContext);

  const checkReceiptInfo = () => {
    setLoading(true);
    setReceiptErrorMessage("");
    props.checkReceiptInfo(receiptType, receiptPhone, receiptEmail);
  };

  useEffect(() => {
    setLoading(false);
    if (props.receiptData?.status) {
      UnitPurchaseData.setReceiptType(receiptType);
      UnitPurchaseData.setReceiptPhone(receiptPhone);
      UnitPurchaseData.setReceiptEmail(receiptEmail);
      if (props.editMode) {
        props.updateReceiptType(receiptType, receiptPhone, receiptEmail);
      } else {
        UnitPurchaseData.updateUnitStep("Select Tip");
      }
    } else if (props.receiptData?.status === false) {
      setReceiptErrorMessage(props.receiptData.message);
    }
  }, [props.receiptData]);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="row">
      <div className="col-sm-12 text-center">
        <div className="container px-2 py-3">
          {receiptErrorMessage && (
            <div className="row">
              <div className="col-12 px-1">
                <div className="alert alert-danger">{receiptErrorMessage}</div>
              </div>
            </div>
          )}
          <div className="row mb-1">
            <div className="col-6 px-1">
              <ItemRadio
                label="Send by Phone"
                name="receiptType"
                id="receiptTypePhone"
                value="phone"
                placeholder="Phone"
                checked={receiptType === "phone"}
                onChange={(value) => setReceiptType(value)}
              />
            </div>
            <div className="col-6 px-1">
              <ItemRadio
                label="Send by Email"
                name="receiptType"
                id="receiptTypeEmail"
                value="email"
                placeholder="Email"
                checked={receiptType === "email"}
                onChange={(value) => setReceiptType(value)}
              />
            </div>
          </div>

          {receiptType === "phone" && (
            <FormInput
              label={"Customer Mobile Phone Number"}
              type={"number"}
              id={"receiptPhone"}
              value={receiptPhone}
              autoFocus={true}
              placeholder={"enter mobile phone number"}
              onChange={(value) => setCustomerPhone(value)}
              name="receiptPhone"
            />
          )}

          {receiptType === "email" && (
            <FormInput
              label={"Customer Email"}
              type={"text"}
              id={"receiptEmail"}
              value={receiptEmail}
              placeholder={"enter email address"}
              onChange={(value) => setCustomerEmail(value)}
              name="receiptEmail"
            />
          )}

          {!props.editMode && (
            <div className="row">
              <div className="col-12">
                <button
                  className="btn btn-success mt-2"
                  disabled={
                    loading ||
                    (receiptType === "phone" && !receiptPhone) ||
                    (receiptType === "email" && !receiptEmail) ||
                    !receiptType
                  }
                  onClick={() => {
                    checkReceiptInfo();
                  }}
                >
                  {loading && <i className="fas fa-spinner fa-spin" />} Continue
                </button>
              </div>
            </div>
          )}

          {props.editMode && (
            <div className="row">
              <div className="col-12">
                <button
                  className="btn btn-success m-0 me-2"
                  disabled={
                    loading ||
                    (receiptType === "phone" && !receiptPhone) ||
                    (receiptType === "email" && !receiptEmail) ||
                    !receiptType
                  }
                  onClick={() => {
                    checkReceiptInfo();
                  }}
                >
                  {(loading ||
                    (receiptType === "phone" && !receiptPhone) ||
                    (receiptType === "email" && !receiptEmail) ||
                    !receiptType) && <i className="fas fa-spinner fa-spin" />}
                  Continue
                </button>
                <button
                  type="submit"
                  className="btn btn-warning"
                  onClick={() => props.setShowEditReceipt(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  receiptData: state.units.receiptData,
});

export default connect(mapStateToProps, { checkReceiptInfo })(
  SelectReceiptType,
);
