import React from "react";
import ItemCheckbox from "components/form/itemCheckbox";

const Addons = ({ unit, selectedAddOns, toggleCheckboxChange }) => {
  return (
    <div className="col-6 px-1">
      <ItemCheckbox
        id={"checkbox" + unit.unitType}
        label={unit.name}
        unitPrice={unit.price}
        name="selectedUnit"
        value={unit.unitType}
        checked={
          !!(
            selectedAddOns &&
            selectedAddOns.indexOf("," + unit.unitType.toString() + ",", 0) > -1
          )
        }
        handleCheckboxChange={toggleCheckboxChange}
      />
    </div>
  );
};

export default Addons;
