import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { login, loginAuthCode } from "client/actions/auth";
import p60logo from "../../../assets/p60-logo.png";
import bhlogo from "../../../assets/bh-logo.png";

import { ApiURL } from "../../../config";
import Loading from "../../layout/Loading";

const Login = (props) => {
  const [searchParams] = useSearchParams();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [appVersion, setAppVersion] = useState("");
  const [AUTH2FA, setAUTH2FA] = useState(false);
  const [AuthMessage, setAuthMessage] = useState("");
  const [auth2faCode, setAuth2faCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    if (e.target.name === "username") {
      setUsername(e.target.value);
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
    } else if (e.target.name === "auth2faCode") {
      setAuth2faCode(e.target.value);
    }
  };

  const SubmitLogIn = (e) => {
    setLoading(true);
    e.preventDefault();
    setErrorMessage("");

    let error = false;
    let errorMessage = "";
    if (username === "") {
      error = true;
      errorMessage = "You left your username blank";
    }
    if (password === "") {
      error = true;
      errorMessage = "You left your password blank";
    }
    if (error) {
      setError(error);
      setLoading(false);
      setErrorMessage(errorMessage);
    } else {
      fetch(`${ApiURL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ username, password, appVersion }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (!res.status) {
            setErrorMessage(res.details);
            setError(error);
            setLoading(false);
          } else {
            if (res.AUTH2FA) {
              setAUTH2FA(true);
              setAuthMessage(res.message);
            } else {
              // update login token stuff
              window.recordSession(
                res.user.id,
                res.user.initials,
                res.user.username,
              );
              localStorage.setItem("userInfo", JSON.stringify(res.user));
              localStorage.setItem("authToken", res.token);
              localStorage.setItem("token", res.token);
              localStorage.setItem("appVersion", appVersion);

              if (typeof appVersion !== "undefined" && appVersion) {
                navigate(`/dashboard/?AV=` + appVersion);
              } else {
                navigate(`/dashboard`);
              }
            }
          }
        })
        .catch((err) => {
          console.log("authLogin Error", err);
        });
    }
  };

  const SubmitAuthCode = (e) => {
    e.preventDefault();
    props.loginAuthCode(auth2faCode);
  };

  useEffect(() => {
    if (props.auth.isAuthenticated) {
      localStorage.setItem("userInfo", JSON.stringify(props.auth.user.user));
      localStorage.setItem("authToken", props.auth.token);
      localStorage.setItem("token", props.auth.token);
      localStorage.setItem("appVersion", appVersion);

      if (typeof appVersion !== "undefined" && appVersion) {
        navigate(`/dashboard/?AV=` + appVersion);
      } else {
        navigate(`/dashboard`);
      }
    }
  }, [props.auth]);

  useEffect(() => {
    let appVersion = searchParams.get("AV");
    setAppVersion(appVersion);
  }, [searchParams]);

  return (
    <div className="container mb-4">
      <div className="row">
        <div className="col-md-6 m-auto">
          <div className="card card-body border-warning mt-5 border-4">
            <div className="row">
              <div className="col-12 my-2 text-center">
                <img
                  src={p60logo}
                  className="img-fluid"
                  alt="Company Logo"
                  style={{ width: "400px", height: "auto" }}
                  border="0"
                />
              </div>
              <div className="col-12 my-2 text-center">
                <img
                  src={bhlogo}
                  className="img-fluid"
                  alt="Company Logo"
                  style={{ width: "400px", height: "auto" }}
                  border="0"
                />
              </div>
            </div>

            {AuthMessage && (
              <div className="alert alert-warning">{AuthMessage}</div>
            )}
            {errorMessage && (
              <div className="alert alert-danger">{errorMessage}</div>
            )}
            {!AUTH2FA && (
              <form onSubmit={SubmitLogIn}>
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label text-left">
                    Username
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      name="username"
                      onChange={onChange}
                      className="form-control"
                      autoComplete="section-red username"
                      value={username}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label text-left">
                    Password
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="password"
                      name="password"
                      onChange={onChange}
                      className="form-control"
                      autoComplete="section-red current-password"
                      value={password}
                    />
                  </div>
                </div>
                {loading && <Loading />}
                {!loading && (
                  <div className="d-grid gap-2">
                    <button
                      className="btn btn-warning mt-3"
                      onClick={SubmitLogIn}
                    >
                      LOG IN
                    </button>
                  </div>
                )}
              </form>
            )}
            {AUTH2FA && (
              <form onSubmit={SubmitAuthCode}>
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label text-left">
                    Auth Code
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      name="auth2faCode"
                      onChange={onChange}
                      className="form-control"
                      autoComplete="section-red username"
                      value={auth2faCode}
                    />
                  </div>
                </div>
                <div className="d-grid gap-2">
                  <button
                    className="btn btn-block btn-secondary"
                    onClick={SubmitAuthCode}
                  >
                    Continue
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { login, loginAuthCode })(Login);
