import React, { useContext, useState } from "react";
import { UnitsContext } from "./Dashboard";
import Units from "./Units";
import WaterIcon from "./WaterIcon";

const Location = ({ location, locationCount }) => {
  const { name, locationId } = location;
  let units = location.units;
  const locationStatus = JSON.parse(
    localStorage.getItem("showLocation_" + locationId),
  );

  const UnitsData = useContext(UnitsContext);
  const hasCartUnits = UnitsData?.hasCartUnits;
  const cartUnits = UnitsData?.cartUnits;
  const hasMoveUnit = UnitsData?.hasMoveUnit;
  const moveUnit = UnitsData?.moveUnit;
  const searchTerm = UnitsData?.searchTerm;

  const flashingUnits = location?.units.filter((row) => {
    return row.filter((unit) => unit.status === 7).length;
  });
  const hasFlashingUnits = flashingUnits.length > 0;

  const [showLocation, setShowLocation] = useState(
    locationCount <= 3 || locationStatus,
  );

  // S1 = NOT SETUP (BLANK)
  // S2 = SETUP (GREEN)
  // S3 = RENTED (RED)
  // S4 = RE-RENTED (BLUE)
  // S5 = EMPTY (GREEN)
  // S6 = TOWER / Pier (BLACK)
  // S7 = FLASHING
  // S8 = NEEDS ATTENTION

  const toggleLocation = (status) => {
    localStorage.setItem("showLocation_" + locationId, JSON.stringify(status));
    setShowLocation(status);
  };

  let available = 0;
  if (typeof location.stats === "object" && location.stats !== null) {
    available = location.stats[1] + location.stats[3];
  }

  return (
    <div className="container-fluid location" id={`location` + locationId}>
      <div className="row">
        <div className="col-12">
          <div className="flex-row d-flex align-content-center">
            <h2>
              {showLocation && locationCount > 3 && (
                <button
                  className="btn btn-primary btn-lg me-4"
                  onClick={() => toggleLocation(false)}
                >
                  <i className="fas fa-caret-down" />
                </button>
              )}
              {!showLocation && locationCount > 3 && (
                <button
                  className="btn btn-primary btn-lg me-4"
                  onClick={() => toggleLocation(true)}
                >
                  <i className="fas fa-caret-right" />
                </button>
              )}
              <WaterIcon showFlashing={hasFlashingUnits} /> {` ${name} `}
              {searchTerm && " - " + units.length + " unit(s) found"}
              <WaterIcon showFlashing={hasFlashingUnits} />
            </h2>

            {location.stats && (
              <>
                <div className="bg-warning py-1 px-2 text-dark text-center rounded m-1">
                  {location.stats[0]}
                </div>
                <div className="bg-success py-1 px-2 text-light text-center rounded m-1">
                  {available}
                </div>
                {location.stats[2] !== 0 && (
                  <div className="bg-danger py-1 px-2 text-light text-center rounded m-1">
                    {location.stats[2]}
                  </div>
                )}
                {location.stats[4] !== 0 && (
                  <div className="bg-primary py-1 px-2 text-light text-center rounded m-1">
                    {location.stats[4]}
                  </div>
                )}
                {location.stats[5] !== 0 && (
                  <div className="bg-occupied py-1 px-2 text-light text-center rounded m-1">
                    {location.stats[5]}
                  </div>
                )}
                {location.stats[7] !== 0 && (
                  <div className="bg-occupied-flashing py-1 px-5 text-light text-center rounded m-1">
                    {location.stats[7]}
                  </div>
                )}
                {location.flagged > 0 && (
                  <div className="bg-warning py-1 px-5 text-dark text-center rounded m-1">
                    {location.flagged}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {showLocation && (
        <Units
          units={units}
          hasCartUnits={hasCartUnits}
          cartUnits={cartUnits}
          hasMoveUnit={hasMoveUnit}
          moveUnit={moveUnit}
        />
      )}
    </div>
  );
};

export default Location;
