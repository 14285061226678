import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  saveUnitNumber,
  tokenConfig,
} from "../../../../client/actions/apiActions";

import { UnitPurchaseContext } from "../UnitModal";
import axios from "axios";
import { ApiURL } from "../../../../config";
import { UnitsContext } from "../Dashboard";

const TabNumber = (props) => {
  const UnitsData = useContext(UnitPurchaseContext);
  const unitId = UnitsData.unitId;
  const { getUnits } = useContext(UnitsContext);

  const [unitType, setUnitType] = useState(
    UnitsData.unitData?.umbrellaType ?? "",
  );
  const [unitNumber, setUnitNumber] = useState(
    UnitsData.unitData?.umbrellaNumber ?? "",
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [saving, setSaving] = useState(false);
  const [saveClose, setSaveClose] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const saveCloseUnitNumber = () => {
    setSaving(true);
    setSaveClose(true);
    props.saveUnitNumber(unitId, unitType, unitNumber);
  };

  const resetUnit = async () => {
    let res = await axios.post(
      `${ApiURL}/resetUnit`,
      {
        unitInfo: unitId.split("_"),
      },
      tokenConfig(),
    );

    if (res.data.status) {
      setUnitNumber("");
      setUnitType("");
      setErrorMessage("");
      setSuccessMessage("Unit Reset");
    } else {
      setErrorMessage(res.data.message);
    }
    props.closeModal();
    getUnits();
  };

  useEffect(() => {
    if (props.data.unitId === unitId) {
      setSaving(false);
      if (props.data.status) {
        if (saveClose) {
          setErrorMessage("");
          setUnitType("");
          setUnitNumber("");
          props.updateUnitInfo();
        } else {
          setSuccessMessage("Unit Saved");
        }
      } else {
        setErrorMessage(props.data.message);
      }
    }
  }, [props]);

  useEffect(() => {
    if (UnitsData.unitData?.umbrellaNumber !== "0") {
      setUnitNumber(UnitsData.unitData?.umbrellaNumber ?? "");
    } else {
      setUnitNumber("");
    }
    setUnitType(UnitsData.unitData?.umbrellaType ?? "");
  }, [UnitsData.unitData]);

  return (
    <div className="card panel-primary">
      <div className="card-header">Set Unit</div>
      <div className="card-body">
        <div className="container">
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              <b>ERROR:</b> <span className="ErrorText">{errorMessage}</span>
            </div>
          )}
          {successMessage && (
            <div className="alert alert-success my-2" role="alert">
              {successMessage}
            </div>
          )}
          <form className="form-inline" onSubmit={(e) => e.preventDefault()}>
            <h3>Unit Number</h3>
            {unitType !== "P" && (
              <div className="col-12">
                <input
                  type="number"
                  name="unitNumber"
                  className="form-control form-control-lg border-secondary"
                  autoFocus={true}
                  id="unitNumber"
                  placeholder="Number"
                  onChange={(e) => setUnitNumber(e.target.value)}
                  value={unitNumber}
                />
              </div>
            )}
            <h3 className="mt-4">Unit Type</h3>
            <div className="container">
              <div className="row my-2">
                <UnitType
                  value="U"
                  label="Umbrella"
                  checked={unitType === "U"}
                  onChange={(value) => setUnitType(value)}
                />
                <UnitType
                  value="C"
                  label="Cabana"
                  checked={unitType === "C"}
                  onChange={(value) => setUnitType(value)}
                />
                <UnitType
                  value="B"
                  label="Bed"
                  checked={unitType === "B"}
                  onChange={(value) => setUnitType(value)}
                />
                <UnitType
                  value="X"
                  label="X Chair"
                  checked={unitType === "X"}
                  onChange={(value) => setUnitType(value)}
                />
              </div>
              <div className="row my-2">
                <div className="col-sm-12 pt-2">
                  <h3 className="mt-4">Other</h3>
                </div>
              </div>
              <div className="row my-2">
                <UnitType
                  value="P"
                  label="Pier"
                  checked={unitType === "P"}
                  onChange={(value) => setUnitType(value)}
                />
                <UnitType
                  value="T"
                  label="Tower"
                  checked={unitType === "T"}
                  onChange={(value) => setUnitType(value)}
                />
              </div>
              <div className="col-12" style={{ textAlign: "center" }}>
                <button
                  type="submit"
                  className="btn btn-primary oswald mt-4"
                  disabled={
                    (unitType === "" || unitNumber === "" || saving) &&
                    unitType !== "P"
                  }
                  onClick={saveCloseUnitNumber}
                >
                  SAVE & CLOSE
                </button>
                {(userInfo.userType === "A" || userInfo.userType === "S") &&
                  UnitsData.unitData.umbrellaNumber !== "0" &&
                  UnitsData.unitData.status === "1" && (
                    <>
                      <br />
                      <br />
                      <button
                        type="submit"
                        className="btn btn-danger oswald mt-4"
                        onClick={resetUnit}
                      >
                        RESET UNIT
                      </button>
                    </>
                  )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.pages.data,
});

export default connect(mapStateToProps, {
  saveUnitNumber,
})(TabNumber);

const UnitType = (props) => {
  return (
    <div className="col-sm-6 px-1">
      <label
        className="form-check-label w-100"
        htmlFor={`unitType${props.value}`}
      >
        <div className="input-group input-group-lg w-100 mb-3">
          <span className="input-group-text border-secondary">
            <input
              id={`unitType${props.value}`}
              className="form-check-input"
              type="radio"
              name="unitType"
              value={props.value}
              checked={props.checked}
              onChange={(e) => props.onChange(e.target.value)}
            />
          </span>
          <div className="input-group-text flex-fill border-secondary">
            {props.label}
          </div>
        </div>
      </label>
    </div>
  );
};
