import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { saveCustomer, tokenConfig } from "client/actions/apiActions";
import Loading from "../../../../layout/Loading";
import { UnitsContext } from "../../Dashboard";
import { UnitPurchaseContext } from "../../UnitModal";
import { compileNotes } from "./SelectTip/utils";
import axios from "axios";
import { ApiURL } from "../../../../../config";

const SelectTip = (props) => {
  const UnitsData = useContext(UnitsContext);
  const UnitPurchaseData = useContext(UnitPurchaseContext);
  const ourTransactionId = UnitPurchaseData.unitData.ourTransactionId;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const {
    selectedEquipment,
    selectedAddOns,
    unitId,
    unitData,
    receiptType,
    receiptEmail,
    receiptPhone,
    hotelRoomNumber,
    hotelManualEntry,
    hyattExtraCharge,
    wyndhamExtraCharge,
  } = UnitPurchaseData;

  const {
    hasCartUnits,
    cartUnits,
    checkoutDetails: {
      guestName: orderGuestName,
      hotelName: orderHotelName,
      hotelLastName: orderHotelLastName,
      hotelManualEntry: orderHotelManualEntry,
      hotelRoomNumber: orderHotelRoomNumber,
      hyattExtraCharge: orderHyattExtraCharge,
      wyndhamExtraCharge: orderWyndhamExtraCharge,
    },
  } = UnitsData;

  const transactionID = "";

  const [orderSubTotal, setOrderSubTotal] = useState(0);
  const [orderRetailTotal, setOrderRetailTotal] = useState(0);
  const [addonsTotal, setAddonsTotal] = useState(0);
  const [unitsTotal, setUnitsTotal] = useState(0);

  const [paymentMethod, setPaymentMethod] = useState(
    UnitPurchaseData?.paymentMethod ??
      UnitsData?.checkoutDetails?.paymentMethod,
  );
  const [guestName, setGuestName] = useState(orderGuestName);
  const [hotelName, setHotelName] = useState(orderHotelName);
  const [hotelLastName, setHotelLastName] = useState(orderHotelLastName);

  const [loading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [androidMessage, setAndroidMessage] = useState("");
  const [tipAmount, setTipAmount] = useState(0);
  const [tipType, setTipType] = useState("tip15");
  const [savingCustomer, setSavingCustomer] = useState(false);
  const [processing, setProcessing] = useState(false);

  const selectTip = (clickedTipAmount, tipType) => {
    setTipAmount(clickedTipAmount);
    setTipType(tipType);
  };

  const connectToSQCC = () => {
    setProcessing(true);
    let customerName = UnitPurchaseData.guestName;

    const priceToCharge = (
      parseFloat(orderSubTotal) + parseFloat(tipAmount)
    ).toFixed(2);

    UnitPurchaseData.logAction(
      "loading square to charge " + customerName + " for " + priceToCharge,
    );

    if (window.Android) {
      try {
        setAndroidMessage("Connecting to Square Checkout ...");
        window.Android.ChargeAmount(
          priceToCharge,
          customerName + " " + ourTransactionId,
        );
      } catch (e) {
        setProcessing(false);
        setAndroidMessage("Error running Square Checkout ...");
      }
    } else if (
      !window.Android &&
      (userInfo.userType === "A" || userInfo.userType === "O")
    ) {
      setAndroidMessage("Couldn't connect to 'Android' App");
      window.updateReaderData(true, "success");
    }
  };

  window.completePayment = () => {
    UnitPurchaseData.logAction("tip screen: window.completePayment()");
    checkReaderData();
  };

  const checkReaderData = () => {
    try {
      let readerData = window["getReaderData"]();
      if (
        readerData["success"] === false ||
        readerData["success"] === "false"
      ) {
        setProcessing(false);
        window.resetReaderData();
        setAndroidMessage("Reader returned an error");
        UnitPurchaseData.logAction("Reader returned an error");
      } else if (
        readerData["success"] === true ||
        readerData["success"] === "true"
      ) {
        UnitPurchaseData.logAction("tip screen: checkReaderData()");
        window.resetReaderData();
        completeOrder();
      }
    } catch (e) {
      setProcessing(false);
      setAndroidMessage("Unable to read window function");
    }
  };

  const completeOrder = () => {
    UnitPurchaseData.logAction("tip screen: completeOrder()");
    setProcessing(true);
    let data = {
      receiptType,
      receiptPhone,
      receiptEmail,
      orderSubTotal,
      orderRetailTotal,
      addonsTotal,
      unitsTotal,
      paymentMethod,
      guestName,
      hotelName,
      hotelLastName,
      hotelRoomNumber: hotelRoomNumber ? hotelRoomNumber : orderHotelRoomNumber,
      hotelManualEntry: hotelManualEntry
        ? hotelManualEntry
        : orderHotelManualEntry,
      wyndhamExtraCharge: wyndhamExtraCharge
        ? wyndhamExtraCharge
        : orderWyndhamExtraCharge,
      hyattExtraCharge: hyattExtraCharge
        ? hyattExtraCharge
        : orderHyattExtraCharge,
      selectedEquipment,
      selectedAddOns,
      tipAmount,
      tipType,
      transactionID,
      ourTransactionId,
      hasCartUnits,
      cartUnits,
      currentUnit: UnitPurchaseData,
    };
    let notes = compileNotes(data);
    saveCustomer(notes);
  };

  const saveCustomer = async (notes) => {
    UnitPurchaseData.logAction("tip screen: saveCustomer()");

    // TODO - convert to axios instead of redux
    const customerData = {
      paymentMethod: paymentMethod,
      hotelName: hotelName,
      guestName: guestName !== "" ? guestName : hotelLastName,
      hotelRoomNumber: hotelRoomNumber ? hotelRoomNumber : orderHotelRoomNumber,
      hotelManualEntry: hotelManualEntry
        ? hotelManualEntry
        : orderHotelManualEntry,
      wyndhamExtraCharge: wyndhamExtraCharge
        ? wyndhamExtraCharge
        : orderWyndhamExtraCharge,
      hyattExtraCharge: hyattExtraCharge
        ? hyattExtraCharge
        : orderHyattExtraCharge,

      subTotal: Number.parseFloat(orderSubTotal).toFixed(2),
      addOnsTotal: Number.parseFloat(addonsTotal).toFixed(2),
      tipTotal: Number.parseFloat(tipAmount).toFixed(2),
      orderTotal: Number.parseFloat(orderSubTotal).toFixed(2),
      chargeTotal: (
        Number.parseFloat(orderSubTotal) + Number.parseFloat(tipAmount)
      ).toFixed(2),
      retailTotal: Number.parseFloat(orderRetailTotal).toFixed(2),

      selectedEquipment: selectedEquipment,
      selectedAddOns: selectedAddOns,
      transactionID: transactionID,

      notes: notes,
      ourTransactionId: ourTransactionId,
      cartUnits: { ...cartUnits, unitData },
      hasCartUnits: hasCartUnits,
      receiptType: receiptType,
      receiptPhone: receiptPhone,
      receiptEmail: receiptEmail,
      tipType: tipType,
      unitId: unitId,
      unitData: unitData,
    };
    const res = await axios.post(
      `${ApiURL}/saveCustomer`,
      { customerData: customerData },
      tokenConfig(),
    );

    if (res.data.success) {
      UnitPurchaseData.getUnits();
      UnitPurchaseData.closeModal();
    } else {
      setErrorMessage(res.data.message);
      setProcessing(false);
    }
  };

  useEffect(() => {
    window.resetReaderData();

    let orderSubTotal = 0;
    let orderRetailTotal = 0;
    let addonsTotal = 0;
    let unitsTotal = 0;
    let paymentMethod = "";
    let guestName = "";
    let hotelName = "";
    let hotelLastName = "";

    if (hasCartUnits) {
      let unitsCalculate = [];
      cartUnits.forEach((item) => {
        unitsCalculate.push(item);
      });
      unitsCalculate.push({
        ...UnitPurchaseData.unitData,
        subTotal: UnitPurchaseData.subTotal,
        addOnsTotal: UnitPurchaseData.addOnsTotal,
        retailTotal: UnitPurchaseData.retailTotal,
      });

      unitsCalculate.forEach((item) => {
        unitsTotal += Number.parseFloat(item.subTotal);
        addonsTotal += Number.parseFloat(item.addOnsTotal);
        orderSubTotal +=
          Number.parseFloat(item.subTotal) +
          Number.parseFloat(item.addOnsTotal);
        orderRetailTotal +=
          Number.parseFloat(item.retailTotal) +
          Number.parseFloat(item.addOnsTotal);
      });

      paymentMethod = UnitsData.checkoutDetails.paymentMethod;
      guestName = UnitsData.checkoutDetails.guestName;
      hotelName = UnitsData.checkoutDetails.hotelName;
      hotelLastName = UnitsData.checkoutDetails.hotelLastName;
    } else {
      unitsTotal += Number.parseFloat(UnitPurchaseData.subTotal);
      addonsTotal += Number.parseFloat(UnitPurchaseData.addOnsTotal);
      orderSubTotal +=
        Number.parseFloat(UnitPurchaseData.subTotal) +
        Number.parseFloat(UnitPurchaseData.addOnsTotal);
      orderRetailTotal +=
        Number.parseFloat(UnitPurchaseData.retailTotal) +
        Number.parseFloat(UnitPurchaseData.addOnsTotal);

      paymentMethod = UnitPurchaseData.paymentMethod;
      guestName = UnitPurchaseData.guestName;
      hotelName = UnitPurchaseData.hotelName;
      hotelLastName = UnitPurchaseData.hotelLastName;
    }

    if (
      UnitPurchaseData?.paymentMethod === "Voucher" ||
      UnitsData?.checkoutDetails?.paymentMethod === "Voucher" ||
      UnitPurchaseData?.paymentMethod === "Cash" ||
      UnitsData?.checkoutDetails?.paymentMethod === "Cash"
    ) {
      setTipAmount(0);
      setTipType("noTip");
    } else {
      setTipAmount(orderRetailTotal * 0.15);
      setTipType("tip15");
    }

    setOrderSubTotal(orderSubTotal);
    setOrderRetailTotal(orderRetailTotal);
    setAddonsTotal(addonsTotal);
    setUnitsTotal(unitsTotal);
    setPaymentMethod(paymentMethod);
    setGuestName(guestName);
    setHotelName(hotelName);
    setHotelLastName(hotelLastName);

    UnitPurchaseData.logAction(
      "tip screen loaded: " +
        (hasCartUnits ? "multi-unit" : "single-unit") +
        " paymentMethod " +
        paymentMethod,
    );
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="row">
        <div className="col-12 text-center">
          <div className="container">
            <div className="row mt-4 justify-content-center">
              <div className="col-xs-8 col-sm-8 col-md-6 col-lg-4 col-12">
                <h3 className="text-center">Review Order</h3>
                <table className="table table-striped table-sm">
                  <tbody>
                    <tr>
                      <td className="text-end">Addons:</td>
                      <td className="text-start">
                        ${Number.parseFloat(addonsTotal).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-end">Equipment:</td>
                      <td className="text-start">
                        ${Number.parseFloat(unitsTotal).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-end">Sub Total:</td>
                      <td className="text-start">
                        ${Number.parseFloat(orderSubTotal).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-end">Retail Value:</td>
                      <td className="text-start">
                        ${Number.parseFloat(orderRetailTotal).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-end">Tip:</td>
                      <td className="text-start">
                        ${Number.parseFloat(tipAmount).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-end">Charge Total:</td>
                      <td className="text-start">
                        $
                        {(
                          parseFloat(orderSubTotal) + parseFloat(tipAmount)
                        ).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 text-center">
          <div className="container py-2">
            {paymentMethod !== "Voucher" && paymentMethod !== "Cash" && (
              <>
                <div className="row mb-2">
                  <div className="col">
                    <h3 className="text-center">
                      <i className="fas fa-umbrella-beach" />
                    </h3>
                  </div>
                  <div className="col-auto">
                    <h3 className="text-center">
                      Thank the crew for
                      <br />
                      keeping you cool?
                    </h3>
                  </div>
                  <div className="col">
                    <h3 className="text-center">
                      <i className="fas fa-umbrella-beach" />
                    </h3>
                  </div>
                </div>
                {errorMessage && (
                  <div className="alert alert-danger">{errorMessage}</div>
                )}

                <br />
                <div className="row mb-1">
                  <div className="col">
                    <button
                      className={`btn btn-lg ${
                        tipAmount === parseFloat(orderRetailTotal) * 0.2
                          ? "btn-warning"
                          : "btn-outline-secondary"
                      } mr-1`}
                      onClick={() =>
                        selectTip(parseFloat(orderRetailTotal) * 0.2, "tip20")
                      }
                    >
                      20%
                      <br />
                      ($
                      {(parseFloat(orderRetailTotal) * 0.2).toFixed(2)}
                      )<br />
                      <h1>
                        <i className="fas fa-sun" />
                      </h1>
                    </button>

                    <button
                      className={`btn btn-lg ${
                        tipAmount === parseFloat(orderRetailTotal) * 0.15
                          ? "btn-warning"
                          : "btn-outline-secondary"
                      } m-1`}
                      onClick={() =>
                        selectTip(parseFloat(orderRetailTotal) * 0.15, "tip15")
                      }
                    >
                      15%
                      <br />
                      ($
                      {(parseFloat(orderRetailTotal) * 0.15).toFixed(2)}
                      )<br />
                      <h1>
                        <i className="fas fa-cloud-sun" />
                      </h1>
                    </button>

                    <button
                      className={`btn btn-lg ${
                        tipAmount === parseFloat(orderRetailTotal) * 0.1
                          ? "btn-warning"
                          : "btn-outline-secondary"
                      } m-1`}
                      onClick={() =>
                        selectTip(parseFloat(orderRetailTotal) * 0.1, "tip10")
                      }
                    >
                      10%
                      <br />
                      ($
                      {(parseFloat(orderRetailTotal) * 0.1).toFixed(2)}
                      )<br />
                      <h1>
                        <i className="fas fa-cloud" />
                      </h1>
                    </button>

                    <button
                      className={`btn btn-lg ${
                        tipAmount === 0
                          ? "btn-warning"
                          : "btn-outline-secondary"
                      } ml-1`}
                      onClick={() => selectTip(0, "noTip")}
                    >
                      {" "}
                      <br />
                      No Tip
                      <br />
                      <h1>
                        <i className="fas fa-poo-storm" />
                      </h1>
                    </button>
                  </div>
                </div>
              </>
            )}
            {paymentMethod === "Credit Card" && (
              <>
                {androidMessage !== "" && (
                  <div className="row my-2">
                    <div className="col-12 bg-light rounded">
                      {androidMessage} &nbsp;
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-sm-12 col-md-6 offset-md-3 my-4 text-center">
                    <button
                      className="btn btn-warning btn-lg my-5"
                      onClick={() => {
                        connectToSQCC();
                      }}
                      disabled={processing}
                    >
                      {processing && (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />{" "}
                        </>
                      )}
                      Ready to Pay
                    </button>
                  </div>
                </div>
              </>
            )}

            {paymentMethod !== "Credit Card" && paymentMethod !== "" && (
              <div className="row">
                <div className="col-sm-12 col-md-6 offset-md-3 my-4 text-center">
                  <button
                    className="btn btn-success btn-lg btn-lg my-5"
                    onClick={completeOrder}
                    disabled={processing}
                  >
                    {processing && (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                      </>
                    )}
                    Complete Payment
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  unitData: state.units.unitData,
});

export default connect(mapStateToProps, { saveCustomer })(SelectTip);
