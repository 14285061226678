export const languageText = "en";
export const ApiURL = getEnvironment();

function getEnvironment() {
  let ApiURL;
  if (window.location.hostname.indexOf("dev") !== -1) {
    ApiURL = "https://dev.shadepatrol.com/apicalls";
  } else if (window.location.hostname.indexOf("tmp") !== -1) {
    ApiURL = "https://tmp.shadepatrol.com/apicalls";
  } else if (window.location.hostname.includes("localhost")) {
    ApiURL = "http://localhost/apicalls";
  } else {
    ApiURL = "https://www.shadepatrol.com/apicalls";
  }
  return ApiURL;
}
