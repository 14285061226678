import React from "react";

import FormInputText from "./FormInputText";
import FormInputToggle from "./FormInputToggle";

const FormInput = (props) => {
  let { inputSize, floatingLabel } = props;

  let inputSizeClass = "form-control";
  if (inputSize === "sm") inputSizeClass += " form-control-sm";
  if (inputSize === "lg") inputSizeClass += " form-control-lg";

  let floatingLabelClass = "form-group";
  if (floatingLabel) floatingLabelClass = " form-floating";

  let divColSize = 12;
  if (props.colSize === 0) divColSize = 0;
  if (props.colSize === 1) divColSize = 12;
  if (props.colSize === 2) divColSize = 6;
  if (props.colSize === 3) divColSize = 4;
  if (props.colSize === 4) divColSize = 8;

  props = { ...props, inputSizeClass, floatingLabelClass, divColSize };

  switch (props.type) {
    case "email":
      return <FormInputText {...props} />;

    case "number":
      return <FormInputText {...props} />;

    case "toggle":
      return <FormInputToggle {...props} />;

    case "text":
      return <FormInputText {...props} />;

    default:
      return <FormInputText {...props} />;
  }
};

export default FormInput;
