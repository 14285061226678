import React, { useContext } from "react";

import { UnitPurchaseContext } from "../UnitModal";

const TabLog = (props) => {
  const UnitsData = useContext(UnitPurchaseContext);
  const logs = UnitsData.unitData.logs;

  return (
    <div className="card panel-primary">
      <div className="card-header">Unit Log</div>
      <div className="card-body">
        {logs && (
          <table className="mt-3 table table-striped">
            <thead className="table-dark text-light">
              <tr>
                <th width="10%">User</th>
                <th width="10%">Date</th>
                <th width="80%">Note</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((note, index) => (
                <tr key={index}>
                  <td>{note.user}</td>
                  <td>{note.epoc}</td>
                  <td>{note.note}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default TabLog;
