import React, { useContext, useEffect, useState } from "react";
import { tokenConfig } from "client/actions/apiActions";

import { UnitPurchaseContext } from "../UnitModal";
import axios from "axios";
import { ApiURL } from "../../../../config";

const TabNotes = () => {
  const [customNotes, setCustomNotes] = useState("");
  const [saving, setSaving] = useState(false);
  const UnitPurchaseData = useContext(UnitPurchaseContext);
  const UnitsData = useContext(UnitPurchaseContext);
  const [loading, setLoading] = useState(false);

  const [notes, setNotes] = useState([]);
  // const notes = UnitsData.unitData.notes;
  const unitId = UnitsData.unitId;
  const flagged = parseInt(UnitsData.unitData.flagged);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const onChange = (e) => setCustomNotes(e.target.value);

  const getNotes = async () => {
    setLoading(true);
    const res = await axios.get(
      `${ApiURL}/getUnitNotes/${unitId}`,
      tokenConfig(),
    );
    if (res.data?.success) {
      setNotes(res.data.notes);
    }
    setLoading(false);
  };

  const onSaveNotes = async (needsAttention = false) => {
    setSaving(true);
    const res = await axios.post(
      `${ApiURL}/saveUnitNotes/${unitId}`,
      {
        customNotes,
        needsAttention: needsAttention === true ? "1" : "0",
        flaggedBy: userInfo?.userType,
      },
      tokenConfig(),
    );
    setSaving(false);
    if (res.data?.success) {
      UnitPurchaseData.getUnits();
      UnitPurchaseData.closeModal();
    }
  };

  const unFlagUnit = async () => {
    setSaving(true);
    const res = await axios.post(
      `${ApiURL}/unFlagUnit/${unitId}`,
      {},
      tokenConfig(),
    );
    setSaving(false);
    if (res.data?.success) {
      UnitPurchaseData.getUnits();
      UnitPurchaseData.closeModal();
    }
  };

  useEffect(() => {
    getNotes();
  }, []);

  return (
    <div className="card panel-primary">
      <div className="card-header">Unit Notes</div>
      <div className="card-body">
        {notes && (
          <table className="mt-3 table table-striped">
            <thead className="table-dark text-light">
              <tr>
                <th width="10%">User</th>
                <th width="10%">Date</th>
                <th width="80%">Note</th>
              </tr>
            </thead>
            <tbody>
              {notes.length > 0 &&
                notes.map((note, index) => {
                  if (
                    note.flaggedBy !== null &&
                    note.flaggedBy !== userInfo?.userType &&
                    note.flaggedBy !== "U"
                  ) {
                    return null;
                  }
                  return (
                    <tr
                      key={index}
                      className={
                        note.flagged === 1 || note.flagged === "1"
                          ? "table-warning"
                          : null
                      }
                    >
                      <td>{note.user}</td>
                      <td>{note.epoc}</td>
                      <td>{note.note.replace("LOG:", "")}</td>
                    </tr>
                  );
                })}
              {!loading && notes.length === 0 && (
                <tr>
                  <td colSpan="3">No notes found</td>
                </tr>
              )}
              {loading && notes.length === 0 && (
                <tr>
                  <td colSpan="3">
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Loading...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        <div className="row">
          <div className="col-12">
            <h3>Enter Custom Notes</h3>
          </div>
          <div className="col-12">
            <textarea
              className="form-control border-secondary"
              name="customNotes"
              value={customNotes}
              onChange={onChange}
            />
          </div>
          {(customNotes || flagged === 1 || flagged === "1") && (
            <div className="col-12">
              {customNotes && (
                <>
                  <button
                    className="btn btn-success my-2"
                    onClick={onSaveNotes}
                    disabled={saving}
                  >
                    {saving && (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                      </>
                    )}
                    Save Note
                  </button>{" "}
                  <button
                    className="btn btn-warning my-2"
                    onClick={() => onSaveNotes(true)}
                    disabled={saving}
                  >
                    {saving && (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                      </>
                    )}
                    Save Note & Alert Manager
                  </button>
                </>
              )}
              {(flagged === 1 || flagged === "1") && (
                <>
                  {" "}
                  <button
                    className="btn btn-warning my-2"
                    onClick={unFlagUnit}
                    disabled={saving}
                  >
                    {saving && (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                      </>
                    )}
                    Unflag Unit
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TabNotes;
