import Location from "./Location";
import { useEffect } from "react";

const Locations = ({ locations, scrollToPos }) => {
  useEffect(() => {
    scrollToPos();
  }, []);
  return (
    <>
      {locations.length > 0 &&
        locations.map((location, index) => (
          <Location
            key={`location${index}`}
            location={location}
            locationCount={locations.length}
          />
        ))}
      {locations.length === 0 && (
        <div className="container">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="card card-body border-warning mt-3">
                <div className="row">
                  <div className="col-12 my-2 text-center">
                    <h1 className="text-center">No Locations Assigned</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Locations;
