import React from "react";
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "client/actions/auth";
import { ToastContainer } from "react-toastify";

const AppTemplate = (props) => {
  const { children } = props;
  return (
    <>
      {!children && <Outlet />}
      {children}
      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(AppTemplate);
