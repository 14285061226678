import React, { Fragment, useContext, useEffect } from "react";
import { UnitsContext } from "../../../Dashboard";
import { UnitPurchaseContext } from "../../../UnitModal";

const Items = ({ unitData }) => {
  let selectedAddOns = [];
  if (unitData.selectedAddOns && unitData.selectedAddOns !== ",") {
    selectedAddOns = unitData.selectedAddOns.split(",");
  }

  return (
    <>
      <tr>
        <td>{unitData.umbrellaType + unitData.umbrellaNumber}</td>
        <td>{unitData.selectedEquipment}</td>
        <td>$ {Number.parseFloat(unitData.subTotal).toFixed(2)}</td>
      </tr>

      {selectedAddOns && selectedAddOns.length > 1 && (
        <>
          {selectedAddOns.map((addon, index2) => {
            return (
              <Fragment key={index2}>
                {addon !== "" && (
                  <>
                    {unitData.equipmentData.addons.map((addonName, index3) => {
                      if (addonName.unitType === addon) {
                        return (
                          <tr key={index3}>
                            <td>&nbsp;</td>
                            <td>{addonName.name}</td>
                            <td>
                              $ {Number.parseFloat(addonName.price).toFixed(2)}
                            </td>
                          </tr>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </>
                )}
              </Fragment>
            );
          })}
        </>
      )}
    </>
  );
};

export default Items;
