import React from "react";
import ItemRadio from "components/form/itemRadio";

const Equipment = ({ unit, onChangeRadio, selectedEquipment }) => {
  return (
    <div className="col-6 px-1">
      <ItemRadio
        id={"unitType" + unit.unitType}
        label={unit.name}
        name="selectedUnit"
        value={unit.unitType}
        unitPrice={unit.price}
        onChange={onChangeRadio}
        checked={selectedEquipment === unit.unitType}
      />
    </div>
  );
};

export default Equipment;
