import React, { useContext, useState } from "react";
import { UnitsContext } from "../Dashboard";
import { UnitPurchaseContext } from "../UnitModal";

import axios from "axios";
import { ApiURL } from "../../../../config";
import { tokenConfig } from "client/actions/apiActions";

const TabMoveCustomer = (props) => {
  const UnitsData = useContext(UnitsContext);
  const UnitPurchaseData = useContext(UnitPurchaseContext);
  const [saving, setSaving] = useState(false);

  const confirmMove = async () => {
    setSaving(true);
    try {
      let res = await axios.post(
        `${ApiURL}/moveCustomer`,
        {
          unitId: UnitsData.moveUnit[0],
          newUnitId:
            UnitPurchaseData.unitData.locationsId +
            "_" +
            UnitPurchaseData.unitData.umbrellaRow +
            "_" +
            UnitPurchaseData.unitData.umbrellaPosition +
            "_" +
            UnitPurchaseData.unitData.umbrellaNumber,
          customerId: UnitsData.moveUnit[1],
        },
        tokenConfig(),
      );

      setSaving(false);

      if (res.data.status) {
        UnitsData.getUnits();
        UnitsData.closeModal();
      }
    } catch (err) {
      console.log("logAction: ", err);
    }
  };

  return (
    <div className="card panel-primary">
      <div className="card-header">Move Customer</div>
      <div className="card-body">
        <div className="container">
          <p>Move Customer to this Unit?</p>
          <button
            className={"btn btn-success"}
            onClick={() => {
              confirmMove();
            }}
            disabled={saving}
          >
            {saving && <i className="fas fa-spinner fa-spin" />} Confirm Move
          </button>{" "}
          &nbsp; &nbsp; &nbsp;
          <button
            className={"btn btn-warning"}
            onClick={UnitsData.closeModal}
            disabled={saving}
          >
            Cancel Move
          </button>
        </div>
      </div>
    </div>
  );
};
export default TabMoveCustomer;
