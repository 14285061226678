import { combineReducers } from 'redux';

import unitsReducer from './unitsReducer';
import customersReducer from './customersReducer';
import pagesReducer from './pagesReducer';
import authReducer from "./authReducer"
import errorsReducer from "./errorsReducer"
import messageReducer from "./messageReducer"

export default combineReducers({
    units: unitsReducer,
    updates: customersReducer,
    pages: pagesReducer,
    auth: authReducer,
    errors: errorsReducer,
    messages: messageReducer
})